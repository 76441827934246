import styled from 'styled-components';
export const S = {
    Header: styled.header `
    position: sticky;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-width: 100%;
    padding: 16px 24px;
    background-color: ${(props) => props.theme.colors.header_background};
  `,
    Logo: styled.img `
    width: 100%;
    height: auto;
    max-width: 90px;
  `,
    Navigation: styled.nav `
    display: block;

    @media (max-width: 1204px) {
      display: none;
    }
  `,
    List: styled.ul `
    display: flex;
    align-items: center;
    gap: 24px;
  `,
    Item: styled.li `
    position: relative;
    color: ${(props) => props.theme.colors['--dark-blue-gray']};
    text-decoration: none;
    overflow: hidden;
    transition: color 0.3s ease;

    &::before {
      content: '';
      position: absolute;
      z-index: 1000;
      width: 0; /* Початкова ширина 0 */
      height: 1px;
      background-color: ${(props) => props.theme.colors['--Color-Border-Blue']};
      bottom: 0;
      left: 50%; /* Початок з центру */
      transform: translateX(-50%);
      transition: width 0.5s ease; /* Анімація зміни ширини */
    }

    &:hover::before {
      width: 100%; /* Розширення лінії в обидва боки */
      left: 50%; /* Залишаємо початок з центру */
      transform: translateX(-50%); /* Зміщення в центр */
    }

    &:hover {
      color: ${(props) => props.theme.colors['--Color-Border-Blue']}; /* Зміна кольору тексту при наведенні */
    }

    &:not(:hover)::before {
      width: 0; /* При втраті фокусу лінія знову стискається до центру */
      left: 50%; /* Залишаємо початок з центру */
      transform: translateX(-50%);
    }

    a {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  `,
    Buttons: styled.div `
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 1204px) {
      display: none;
    };
  `,
    BurgerButton: styled.button `
    display: none;

    @media (max-width: 1204px) {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 30px;
      height: 25px;
      background: transparent;
      border: none;
      cursor: pointer;
      transition: transform 0.2s ease-in-out;

      &:hover {
        transform: scale(1.05); /* Легке збільшення при наведенні */
      }

      span {
        width: 100%;
        height: 3px;
        background-color: ${({ $isOpen, theme }) => ($isOpen ? '#e22200' : theme.colors['--Color-Border-Blue'])}; /* Зміна кольору при відкриванні */
        border-radius: 2px;
        transition: all 0.3s ease;
        position: relative;
        transform-origin: center; /* Центрування трансформації */

        &:nth-child(1) {
          transform: ${({ $isOpen }) => ($isOpen ? 'rotate(45deg)' : 'rotate(0)')};
          top: ${({ $isOpen }) => ($isOpen ? '9px' : '0')}; /* Коригуємо позицію після обертання */
        }

        &:nth-child(2) {
          opacity: ${({ $isOpen }) => ($isOpen ? '0' : '1')};
          transform: ${({ $isOpen }) => ($isOpen ? 'scale(0)' : 'scale(1)')}; /* Додаємо анімацію зменшення для середньої лінії */
        }

        &:nth-child(3) {
          transform: ${({ $isOpen }) => ($isOpen ? 'rotate(-45deg)' : 'rotate(0)')};
          bottom: ${({ $isOpen }) => ($isOpen ? '8px' : '0')}; /* Коригуємо позицію після обертання */
        }
      }
    }
  `,
};
