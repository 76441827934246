import img1 from '../../assets/images/Business-Accounts-details/2.png';
import img2 from '../../assets/images/Business-Accounts-details/3.png';
import img3 from '../../assets/images/Business-Accounts-details/4.png';
import img4 from '../../assets/images/Business-Accounts-details/5.png';
import img5 from '../../assets/images/Business-Accounts-details/6.png';
import img6 from '../../assets/images/Business-Accounts-details/7.png';
import img7 from '../../assets/images/Business-Accounts-details/8.png';
import img8 from '../../assets/images/Business-Accounts-details/9.png';
import img9 from '../../assets/images/Business-Accounts-details/10.png';
import img10 from '../../assets/images/Business-Accounts-details/11.png';
import img11 from '../../assets/images/Business-Accounts-details/12.png';
import img12 from '../../assets/images/Business-Accounts-details/13.png';
import img13 from '../../assets/images/Business-Accounts-details/14.png';
export const data = [
    {
        images: [img1, img2],
        title: 'Business username',
        desc: 'Businesses on Ello can secure their brand name as their username, making it easy for customers to find and connect with them through a professional, branded business profile.​​​​​​​​​​​​​​​​',
    },
    {
        images: [img4, img3],
        title: 'Cloud business technology',
        desc: 'With simultaneous access across desktop, mobile, and web platforms, businesses can ensure uninterrupted customer service and team collaboration, as all conversations and files stay perfectly synchronized across every device in real-time.​​​​​​​​​​​​​​​​',
    },
    {
        images: [img5, img6],
        title: 'Collaborate with your team',
        desc: 'Manage multiple accounts with ease. Whether you’re juggling work and personal profiles or switching between different user identities, you can do so seamlessly without logging in and out.',
    },
    {
        images: [img7],
        title: 'Switch between accounts',
        desc: 'Stay informed and entertained with a mix of free and premium channels. Explore content that suits your interests, with options for both free access and subscription-based services for enhanced features.',
    },
    {
        images: [img8, img9],
        title: 'Free and paid channels',
        desc: 'Create or join groups where you can interact with like-minded people, share interests, and plan activities. Whether for socializing, collaborating, or organizing events, groups help keep everyone in sync.',
    },
    {
        images: [img10, img11],
        title: 'Groups to interact with customers',
        desc: 'Share your real-time location with friends or family members for coordinated meetups, added safety, or just staying in touch. You have full control over when and with whom you share your location.',
    },
    {
        images: [img12, img13],
        title: 'Showcase, promote and sell your products',
        desc: 'Share your real-time location with friends or family members for coordinated meetups, added safety, or just staying in touch. You have full control over when and with whom you share your location.',
    },
];
