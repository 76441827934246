import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useEffect, useState, useRef, } from 'react';
import Flag from 'react-world-flags';
import useOutsideClick from '../../hooks/useOutsideClick';
import ArrowDownSvg from '../../assets/svg-icon/arrow-down.svg';
export const CountrySelect = ({ addCountry, countriesList, field, form: { errors }, }) => {
    const [countries, setCountries] = useState(countriesList);
    const [countriesDefault, setCountriesDefault] = useState(countriesList);
    const [text, setText] = useState('');
    const ref = useRef(null);
    const [onOpen, setOnOpen] = useState(false);
    const [src, setSrc] = useState('');
    // const [selectedCountry, setSelectedCountry] = useState<ICountry>({
    //   value: '',
    //   label: '',
    // });
    useOutsideClick(ref, () => setOnOpen(false));
    const handleSelect = (country) => {
        // setSelectedCountry(country);
        setOnOpen(false);
        setText(country.country_name);
        addCountry?.(String(country.id));
    };
    const showAll = () => {
        setText('All');
        addCountry?.('');
        setOnOpen(false);
    };
    const onInput = (event) => {
        const { value } = event.target;
        const countriesAll = countriesDefault?.filter((country) => country.country_name
            .trim()
            .toLowerCase()
            .slice(0, value.toLowerCase().length) === value.toLowerCase());
        setText(value);
        addCountry?.(value);
        // dispatch(putCounties(countriesAll));
        setCountries(countriesAll || []);
        setOnOpen(true);
    };
    useEffect(() => {
        setCountriesDefault(countriesList);
        setCountries(countriesList);
        setSrc(ArrowDownSvg);
    }, [countriesList]);
    return (_jsx("div", { className: "select-custom", children: _jsxs("div", { ref: ref, className: classNames('select', { active: onOpen }), children: [_jsxs("div", { className: "input input-custom pointer", children: [_jsx("label", { className: "label-custom", htmlFor: "/", children: "Country" }), _jsxs("div", { "aria-hidden": true, onClick: () => setOnOpen(true), onFocus: () => setOnOpen(true), className: "input-wrapper", children: [_jsx("input", { onChange: onInput, className: "input-item input-item--left-icon form-control as-disabled", type: "text", name: "country", value: text }), _jsx("div", { className: "input-icon input-icon--right", children: _jsx("img", { src: src, alt: "" }) })] }), errors[field.name] && (_jsx("p", { className: "input-notification--error bottom-error", children: String(errors[field.name]) }))] }), _jsx("div", { className: "select__drop select__drop-custom select__drop-custom-country", children: _jsx("div", { className: "select__drop-scroll select__drop-scroll-custom", children: _jsx("div", { className: "select__drop-item", children: _jsxs("ul", { children: [_jsx("li", { className: "countries-list", children: _jsx("button", { onClick: showAll, type: "button", children: "All" }) }), countries?.map((country) => (_jsx("li", { className: "countries-list", children: _jsxs("button", { onClick: () => handleSelect(country), type: "button", children: [_jsx(Flag, { code: country.country_code === 'DS'
                                                        ? 'AS'
                                                        : country.country_code }), country.country_name] }) }, country.id)))] }) }) }) })] }) }));
};
