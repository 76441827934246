import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useParallaxController, Parallax } from 'react-scroll-parallax';
import { useParams } from 'react-router-dom';
// import { CylinderButton } from '../../components/CylinderButton/CylinderButton';
import { TextContainer } from '../../components/TextContainer/TextContainer';
import { Accounts } from '../../components/Accounts/Accounts';
import { Content } from '../../components/Content/Content';
import { S as Styles } from '../AdvancedEncryption/AdvancedEncryptionStyle';
import { S } from '../PersonalAccounts/PersonalAccountsStyles';
import ello from '../../assets/images/Personal-account-details/ello.png';
import phone from '../../assets/images/Business-Accounts-details/1.png';
import blue1 from '../../assets/images/Business-Accounts-details/Blue1.png';
import blue2 from '../../assets/images/Business-Accounts-details/Blue2.png';
import { data } from './data';
const title = 'Business accounts';
const text = 'Manage your professional activities and interact with clients. Utilize specialized tools and analytics that help you run your business efficiently, promote products and services, and optimize workflows.';
const title2 = 'When to Use a  Business accounts?';
const description2 = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';
const title3 = 'Private or public Account?';
const description3 = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';
export const BusinessAccounts = () => {
    const params = useParams();
    const parallaxController = useParallaxController();
    useEffect(() => {
        window.scrollTo(0, 0);
        const timeout = setTimeout(() => {
            if (parallaxController) {
                parallaxController.update();
            }
        }, 100);
        return () => clearTimeout(timeout);
    }, [params, parallaxController]);
    return (_jsxs(Styles.Wrapper, { children: [_jsx(Styles.Container, { children: _jsx(Styles.Inner, { children: _jsx(TextContainer, { title: title, paragraph: text }) }) }), _jsx(Styles.BG, { "$bg": ello, style: { padding: 0, height: '430px', position: 'relative' }, children: _jsx(S.Image, { children: _jsx("img", { src: phone, alt: "phones" }) }) }), _jsx(S.H2, { children: "Key Features:" }), _jsxs(Styles.Container, { style: { padding: '0 10px', gap: '40px', margin: '0 10px 140px' }, children: [data.map((item, index) => (_jsx(Parallax, { style: { width: '100%' }, scale: [0.2 + index * 0.1, 1], children: _jsx(Accounts, { desc: item.desc, title: item.title, images: item.images }, item.title) }, item.title))), _jsx(S.Wrap, { children: _jsx(Content, { flexReverse: { flexDirection: 'row-reverse' }, title: title2, desc: description2, photo: blue1 }) }), _jsx(S.Wrap, { children: _jsx(Content, { title: title3, desc: description3, photo: blue2 }) })] })] }));
};
