import styled from 'styled-components';
export const S = {
    HeaderForNavFooter: styled.div `
    height: 258px;
    background: url(${({ $bg }) => $bg});
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 10px;
  `,
    HeaderForNavFooterTitle: styled.h2 `
    color: #070708;
    font-family: Roboto;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px;
    max-width: 1137px; 
    margin: 0 auto;
    padding-top: 100px;
  `,
    HeaderForNavFooterSubtitle: styled.h3 `
    color: #070708;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    max-width: 1137px;
    margin: 0 auto;
    padding-top: 16px;
  `,
    HeaderForNavFooterTWrapSvg: styled.div `
    position: absolute;
    left: 20px;
    top: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;

    @media screen and (max-width: 1181px) {
      left: 7px;
    }
  `,
};
