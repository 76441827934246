import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Parallax } from 'react-scroll-parallax';
import { S } from '../PersonalAccounts/PersonalAccountsStyles';
import { CylinderButton } from '../CylinderButton/CylinderButton';
import { TextContainer } from '../TextContainer/TextContainer';
import { ButtonsContainer } from '../ButtonsContainer/ButtonsContainer';
import ListWrapper from '../ListWrapper/ListWrapper';
import img1 from '../../assets/images/Business-accounts/1.png';
import img2 from '../../assets/images/Business-accounts/2.png';
import img3 from '../../assets/images/Business-accounts/3.png';
import { navList } from '../../CONST/nav-list';
import { ERoutesLink } from '../../CONST/routes-link';
const list = [
    'Business username',
    'Accessible on multiple devices',
    'Collaborate with your team',
    'Switch between accounts',
    'Free and paid channels',
    'Groups to interact with customers',
    'Showcase, promote and sell your products',
];
const listImg = [img1, img2, img3];
const title = 'Business accounts';
const titleList = 'Engage with your customers';
const text = 'Manage your professional activities and connect with clients. Use Ello Messenger\'s integrated tools to track client engagement, manage customer relationships, analyze performance, automate workflows, create promotional content, monitor business metrics, utilize AI features, and organize communications - all within a single messaging platform.';
const BusinessAccounts = () => {
    const firstChildGetStyles = () => ({
        left: 'calc(19% - 164px)',
        width: '65%',
        'max-height': '100%',
        'max-width': '100%',
    });
    const secondChildGetStyles = () => ({
        'z-index': '5',
        width: '74%',
        'max-height': '100%',
        'max-width': '100%',
        left: 'calc(3% - 146px)',
    });
    const thirdChildGetStyles = () => ({
        width: '100%',
        'max-height': '100%',
        'max-width': '100%',
        left: 'calc(3% - 240px)',
    });
    return (_jsxs("div", { children: [_jsxs(CylinderButton, { background: "--Color-Orange", color: "--Color-Text-White", children: [_jsxs("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M6.50391 10.7474C6.50391 11.7149 7.24641 12.4949 8.16891 12.4949H10.0514C10.8539 12.4949 11.5064 11.8124 11.5064 10.9724C11.5064 10.0574 11.1089 9.73488 10.5164 9.52488L7.49391 8.47488C6.90141 8.26488 6.50391 7.94238 6.50391 7.02738C6.50391 6.18738 7.15641 5.50488 7.95891 5.50488H9.84141C10.7639 5.50488 11.5064 6.28488 11.5064 7.25238", stroke: "white", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M9 4.5V13.5", stroke: "white", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M11.25 16.5H6.75C3 16.5 1.5 15 1.5 11.25V6.75C1.5 3 3 1.5 6.75 1.5H11.25C15 1.5 16.5 3 16.5 6.75V11.25C16.5 15 15 16.5 11.25 16.5Z", stroke: "white", strokeLinecap: "round", strokeLinejoin: "round" })] }), _jsx("span", { children: "Engage with your customers" })] }), _jsxs(S.TextWrap, { children: [_jsx(TextContainer, { title: title, paragraph: text }), _jsx(ButtonsContainer
                    // btnLeftName="Get App"
                    , { 
                        // btnLeftName="Get App"
                        btnRightName: "Learn More", link: ERoutesLink.BUSINESS_ACCOUNTS, children: _jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "18", height: "19", viewBox: "0 0 18 19", fill: "none", children: [_jsx("path", { d: "M10.8225 4.94727L15.375 9.49977L10.8225 14.0523", stroke: "#0A49A5", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M2.625 9.5H15.2475", stroke: "#0A49A5", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" })] }) })] }), _jsx(S.BusinessWrapper, { style: { paddingBottom: '30px' }, children: _jsx(Parallax, { translateX: [-70, 100], children: _jsx(ListWrapper, { gridTemplateColumns: "0.4fr 1fr", gridRow: { 'grid-row': '1 / 2', left: '-30px' }, list: list, titleList: titleList, listImg: listImg, firstChild: firstChildGetStyles(), secondChild: secondChildGetStyles(), thirdChild: thirdChildGetStyles(), link: ERoutesLink.BUSINESS_ACCOUNTS }) }) }), _jsx("div", { 
                // navigation #
                style: { marginTop: '30px' }, id: navList[2].toLowerCase().replace(' ', '_') }), _jsx("div", { 
                // navigation #
                style: { marginTop: '-30px' }, id: navList[2].toLowerCase().replace(' ', '_') })] }));
};
export default BusinessAccounts;
