import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { navList } from '../../CONST/nav-list';
import { S } from './BurgerMenuStyles';
import { ERoutesLink } from '../../CONST/routes-link';
export const BurgerMenu = ({ isOpen, toggleMenu }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        // eslint-disable-next-line no-alert
        alert('Button clicked!');
    };
    const onAnchor = (index) => {
        index < 4 ? `#${navList[index].toLowerCase().replaceAll(' ', '_')}` : `#${navList[index - 1].toLowerCase()}`;
        if (index === 3) {
            return `#${navList[index - 1].toLowerCase().replace(' ', '_')}`;
        }
        return `#${navList[index].toLowerCase().replace(' ', '_')}`;
    };
    const onLink = (e, index) => {
        e.preventDefault();
        const anchor = onAnchor(index);
        // Спочатку переходимо на головну сторінку
        navigate('/', {
            replace: false, // Додаємо маршрут у стек
        });
        // Прокручуємо до потрібного елемента після переходу
        setTimeout(() => {
            toggleMenu();
            const element = document.querySelector(anchor);
            if (element) {
                element.scrollIntoView();
            }
        }, 100); // Затримка для завантаження сторінки
    };
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen]);
    return (_jsx(S.BurgerWrap, { "$isOpen": isOpen, children: _jsxs(S.BurgerList, { onCanPlay: handleClick, children: [navList.map((link, index) => (_jsx(S.BurgerItem, { children: _jsx(Link, { onClick: (e) => onLink(e, index), to: onAnchor(index), children: link }) }, link))), _jsx(S.BurgerItem, { children: _jsx(Link, { onClick: toggleMenu, to: ERoutesLink.CREATE_BOT, children: "Your AI" }) }), _jsx(S.BurgerItem, { children: _jsx(Link, { onClick: toggleMenu, to: ERoutesLink.SUPPORT, children: "Contact" }) })] }) }));
};
