import styled from 'styled-components';
export const StyleGroup = {
    Groups: styled.div `
    position: relative;
  `,
    ContainerGroup: styled.div `
    width: 100%;
    background-color: white;
    position: relative;
    top: -145px;

    /* @media (max-width: 805px) {
      margin-left: -14px;
    }; */
  `,
    PublicOrPrivate: styled.div `
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;

    h2 {
      color: #070708;
      text-align: center;
      font-family: Poppins;
      font-size: 46px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 64.4px */

      @media (max-width: 768px) {
        font-size: 36px;
        line-height: 55px;
      };
    }
  `,
    PublicOrPrivateContent: styled.div `
    display: flex;
    gap: 20px;
    margin: 50px 10px 0;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    @media (max-width: 525px) {
      margin: 10px 10px 0;
    }
  `,
    PublicOrPrivateCardLeft: styled.div `
    border-radius: 24px;
    background: #F0F6FF;
    padding: 40px;
    width: 100%;

    span {
      color: #070708;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 33.6px */
    }

    p {
      color: #070708;
      font-family: "Public Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 25.2px */
      margin-top: 20px;
    }

    /* @media (max-width: 805px) {
      margin-left: -14px;
    }; */
  `,
    PublicOrPrivateCardRight: styled.div `
    border-radius: 24px;
    background: rgba(212, 239, 223, 0.60);
    padding: 40px;
    width: 100%;

    span {
      color: #070708;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 33.6px */
    }

    p {
      color: #070708;
      font-family: "Public Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 25.2px */
      margin-top: 20px;
    }

    /* @media (max-width: 805px) {
      margin-left: -14px;
    }; */
  `,
    PublicOrPrivateCardCircle: styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    background: #FFF;
    width: 50px;
    height: 50px;
    margin-right: 20px;
  `,
    AvatarOne: styled.div `
    position: absolute;
    right: calc(50% + -335px);
    bottom: calc(50% + 120px);

    @media (max-width: 1242px) {
      right: calc(50% + -194px);
    };

    @media (max-width: 790px) {
      right: calc(50% + -154px);
    };

    @media (max-width: 716px) {
      right: calc(50% + -130px);
    };

    @media (max-width: 579px) {
      right: calc(50% + -90px);
      z-index: 5;
    };

    @media (max-width: 579px) {
      right: calc(50% + -86px);
      bottom: calc(50% + 38px);
    };
  `,
    AvatarTwo: styled.div `
    position: absolute;
    left: calc(50% + -600px);
    top: calc(50% + -310px);

    @media (max-width: 1242px) {
      left: calc(50% + -444px);
      top: calc(50% + -310px);
    };

    @media (max-width: 1100px) {
      top: calc(50% + -252px);
    };

    @media (max-width: 716px) {
      left: calc(50% + -350px);
    };

    @media (max-width: 916px) {
      left: calc(50% + -287px);
      top: calc(50% + -203px);
    };

    @media (max-width: 579px) {
      left: calc(50% + -232px);
      top: calc(50% + -194px);
    };

    @media (max-width: 518px) {
      left: calc(50% + -227px);
      top: calc(50% + -118px);
      z-index: 3;
    };
  `,
    Sms: styled.div `
    position: absolute;
    bottom: 0;
    left: 40px;
    padding: 8px;
    border-radius: 12px;
    background: #FFF;
    width: 255px;

    h4 {
      color: #EF4062;
      font-family: "Public Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      margin-bottom: 8px;

      @media (max-width: 936px) {
        font-size: 12px;
        line-height: 16px;
      };
    }

    p {
      @media (max-width: 936px) {
        font-size: 10px;
        line-height: 12px;
      };
    }
    
    img {
      position: absolute;
      left: -10px;
      bottom: 0;
    }

    span {
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 6px;
      color: #929298;
      text-align: right;
      font-family: "Public Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; /* 116.667% */

      @media (max-width: 936px) {
        font-size: 9px;
        line-height: 12px;
      };
    }

    @media (max-width: 936px) {
      /* width: 100%; */
      max-width: 180px;
    };

    @media (max-width: 717px) {
      /* width: 100%; */
      width: 140px;
    };
  `,
    FunctionalityWrap: styled.div `
    position: relative;
    top: -50px;
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;
  `,
    FunctionalityTytle: styled.h2 `
    color: #070708;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 44.8px */
    max-width: 618px;
    margin-bottom: 16px;
  `,
    FunctionalitySubTitle: styled.p `
    color: #4D4D50;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    max-width: 618px;
  `,
};
