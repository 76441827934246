import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useParallaxController } from 'react-scroll-parallax';
import { S } from './FreePaidStyle';
import { S as Styles } from '../AdvancedEncryption/AdvancedEncryptionStyle';
import { CylinderButton } from '../../components/CylinderButton/CylinderButton';
import { TextContainer } from '../../components/TextContainer/TextContainer';
import { SecondBlock } from '../../components/FreePaidComponent/SecondBlock/SecondBlock';
import { FirstBlock } from '../../components/FreePaidComponent/FirstBlock/FirstBlock';
import { listFreeChannelsFirst, listFreeChannelsSecond, listPaidChannelsFirst, listPaidChannelsSecond, } from './data';
import imgForSecondBlok1 from '../../assets/images/free&chanel/img4.png';
import imgForSecondBlok2 from '../../assets/images/free&chanel/img5.png';
import imgForSecondBlok3 from '../../assets/images/free&chanel/img6.png';
import imgForSecondBlok4 from '../../assets/images/free&chanel/img7.png';
import { WhyChooseElloChannelsBlock } from '../../components/FreePaidComponent/WhyChooseElloChannelsBlock/WhyChooseElloChannelsBlock';
import KeyFeatures from '../../components/FreePaidComponent/KeyFeatures/KeyFeatures';
import img from '../../assets/images/free&chanel/img8.png';
import preview1 from '../../assets/images/Groups/page/Item1.png';
import preview2 from '../../assets/images/Groups/page/Item2.png';
const list = [
    { title: 'Multi-Device Access:', subtitle: 'Stay connected on any device, anytime.' },
    { title: 'Customizable Settings:', subtitle: 'Control who joins, what they see, and how they engage.' },
    { title: 'Seamless Payments:', subtitle: 'Integrated payment options make it easy to collect subscriptions.' },
    { title: 'Analytics and Insights:', subtitle: 'Track your channel\'s growth and engagement in real-time.' },
];
const title = 'Public and subscription channels';
const text = 'Creators, public figures and celebrities - from media organizations and influencers to artists can engage with their fans through customizable channels, offering both free content and premium features to build meaningful connections and generate income.';
const greenText = 'Free Channels:';
const titleList = 'Open and Accessible';
const subTitleList = `
  Create communities that are open to everyone.
  <br />
  With Free Channels, you can:
`;
const subTitleListOther = 'Free Channels are perfect for:';
const imagesSecondBlock = [imgForSecondBlok1, imgForSecondBlok2];
const greenTextPaid = 'Paid Channels:';
const titleListPaid = 'Exclusive Content for Your Inner<br/> Circle';
const subTitleListPaid = `
Monetize your expertise and build a loyal audience. With Paid<br/> Channels, you can:
`;
const subTitleListOtherPaid = 'Paid Channels are ideal for:';
const imagesSecondBlockPaid = [imgForSecondBlok3, imgForSecondBlok4];
export const FreePaid = () => {
    const params = useParams();
    const parallaxController = useParallaxController();
    useEffect(() => {
        window.scrollTo(0, 0);
        const timeout = setTimeout(() => {
            if (parallaxController) {
                parallaxController.update();
            }
        }, 100);
        return () => clearTimeout(timeout);
    }, [params, parallaxController]);
    return (_jsxs(S.FreePaid, { children: [_jsxs("div", { style: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    background: '#f6f8fd',
                    backdropFilter: 'blur(2px)',
                }, children: [_jsx(Styles.Container, { children: _jsxs(Styles.Inner, { style: { margin: '70px 0 0' }, children: [_jsxs(CylinderButton, { background: "--Color-Dark-Green", color: "--Color-Text-White", children: [_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", children: [_jsxs("g", { clipPath: "url(#clip0_3733_51142)", children: [_jsx("rect", { x: "5.25", y: "8.40039", width: "7.5", height: "1.2", fill: "white" }), _jsx("rect", { x: "5.25", y: "11.5498", width: "7.5", height: "1.2", fill: "white" }), _jsx("rect", { x: "5.25", y: "5.25", width: "7.5", height: "1.2", fill: "white" }), _jsx("path", { d: "M9 17.25C13.5563 17.25 17.25 13.5563 17.25 9C17.25 4.44365 13.5563 0.75 9 0.75C4.44365 0.75 0.75 4.44365 0.75 9C0.75 13.5563 4.44365 17.25 9 17.25Z", stroke: "white", strokeLinecap: "round", strokeLinejoin: "round" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_3733_51142", children: _jsx("rect", { width: "18", height: "18", fill: "white" }) }) })] }), _jsx("span", { children: "Stay in touch" })] }), _jsx(TextContainer, { title: title, paragraph: text })] }) }), _jsx(FirstBlock, {})] }), _jsx("div", { style: { width: '100%', padding: '0 10px' }, children: _jsx(SecondBlock, { greenText: greenText, titleList: titleList, subTitleList: subTitleList, listSecondBlockFirst: listFreeChannelsFirst, listSecondBlockSecond: listFreeChannelsSecond, subTitleListOther: subTitleListOther, images: imagesSecondBlock }) }), _jsx("div", { style: { width: '100%', padding: '290px 10px 120px' }, children: _jsx(SecondBlock, { greenText: greenTextPaid, titleList: titleListPaid, subTitleList: subTitleListPaid, listSecondBlockFirst: listPaidChannelsFirst, listSecondBlockSecond: listPaidChannelsSecond, subTitleListOther: subTitleListOtherPaid, images: imagesSecondBlockPaid, reverse: { flexDirection: 'row-reverse' } }) }), _jsx("div", { style: {
                    padding: '0 10px', width: '100%', maxWidth: '1260px', marginBottom: '80px',
                }, children: _jsx("h2", { children: "Key Features of Ello Channels" }) }), _jsx("div", { style: { padding: '0 10px', width: '100%' }, children: _jsx(KeyFeatures, { list: list }) }), _jsx("div", { style: { padding: '0 10px', width: '100%' }, children: _jsx(WhyChooseElloChannelsBlock, { title: "Why Choose Ello Channels?", mainImg: img, paragraphOne: `
            Ello combines simplicity and power, making
            it easy to manage both free and paid
            communities in one place. With secure
            messaging, user-friendly tools, and
            flexible options, Ello empowers you
            to create spaces that reflect your goals.
          `, paragraphTwo: `
            Start your Free or Paid Channel today and
            experience a new way to connect!
          `, imgOne: preview2, imgTwo: preview1 }) })] }));
};
