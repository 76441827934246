import styled from 'styled-components';
export const S = {
    Financial: styled.div `
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
   overflow: hidden;
  `,
    FinancialTransactions: styled.div `
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1260px;
    margin: 75px 0 147px;
    padding: 0 10px 0;

    @media (max-width: 715px) {
      flex-direction: column;
      margin: 75px 0 57px;
    };
  `,
    FinancialTransactionsLeft: styled.div `
    width: 100%;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 140px;
      border: none;
      background: #27AE60;
      padding: 8px 16px;

      span {
        margin-left: 10px;
        color: #FFF;
        text-align: center;
        font-family: "Public Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 19.6px */
      }

      @media (max-width: 715px) {
        margin: 0 auto;
      };
    }

    h2 {
      color: #070708;
      font-family: Poppins;
      font-size: 46px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 64.4px */
      max-width: 310px;
      margin-top: 32px;
    }

    p {
      margin-top: 16px;
      color: #070708;
      font-family: "Public Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 25.2px */
      max-width: 495px;

      @media (max-width: 715px) {
        max-width: 100%;
      };
    }
  `,
    FinancialTransactionsRight: styled.div `
    width: 100%;

    @media (max-width: 895px) {
      display: flex;
      justify-content: flex-end;
    };
    
    @media (max-width: 715px) {
      justify-content: center;
      margin-top: 50px;
    };
  `,
    FinancialDesc: styled.div `
    p {
      color: var(--Color-Text-Dark, #070708);
      font-family: Poppins;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      max-width: 1046px;
      text-align: center;
      margin: 0 auto;

      strong {
        color: var(--Color-Text-Dark, #070708);
        font-weight: 600;
      }

      @media (max-width: 715px) {
        font-size: 20px;
        line-height: 100%;
      };
    }
  `,
};
