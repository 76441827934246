import styled from 'styled-components';
export const S = {
    Chart: styled.div `
    display: flex;
    align-items: center;
    max-width: 1260px;
    width: 100%;
    margin-top: 190px;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
    };
  `,
    ChartLeftContent: styled.div `
    width: 100%;

    h2 {
      color: #070708;
      font-family: "Public Sans";
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 44.8px */
    }

    p {
      color: #070708;
      font-family: "Public Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 25.2px */
      margin: 16px 0 44px;
    }
  `,
    ChartRightContent: styled.div `
    position: relative;
    display: flex;
    width: 100%;
    min-height: 100%;

    @media (max-width: 768px) {
      margin-bottom: 90px;
    };
  `,
    ChartLeftImg: styled.div `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    img {
      position: absolute;
      z-index: 2;

      &:first-child {
        top: -65px;
      };

      &:nth-child(2) {
        top: calc(50% - 180px);
        left: calc(50% - 117px);
      };

      &:last-child {
        bottom: -65px;
      };
    }
  `,
    ChartRightImg: styled.div `
    width: 100%;
    position: relative;
    opacity: 0.4;
    img {
      &:first-child {
        position: relative;
        height: 466px;
        right: -300px;
      }

      &:last-child {
        position: absolute;
        top: 0;
        right: -200px;
      }
    }
  `,
};
