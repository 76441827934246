import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Captcha } from '../Captcha/Captcha';
import useCaptcha from '../../hooks/useCaptcha';
export const CaptchaWrapper = ({ onActiveCaptcha, captchaRef }) => {
    const [token, setToken] = useState(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [error, setError] = useState('');
    const onLoad = useCaptcha();
    useEffect(() => {
        token && onActiveCaptcha(token);
    }, [token]);
    return (_jsx("div", { className: "captcha-wrap", children: _jsx("div", { className: "", children: _jsx(Captcha, { onLoad: () => onLoad(setError, token), setToken: setToken, captchaRef: captchaRef, token: token }) }) }));
};
