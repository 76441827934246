import styled from 'styled-components';
const Wrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 118px);
`;
const CreateBotWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 76px 10px;
  width: 100%;
  min-height: calc(100vh - 118px);
  overflow: hidden;
  margin: 0 0 50px;

  @media (max-width: 768px) {
    overflow: auto;
  };

  @media (max-width: 430px) {
    padding: 16px 10px 76px;
  };
`;
const CreateBot = styled.div `
  max-width: 1260px;
  width: 100%;
  max-height: 641px;
  background-image: url(${({ $bg }) => $bg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;
const CreateBotContent = styled.div `
  position: relative;
  display: grid;
  grid-template-columns: 1fr 0.5fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  };
`;
const CreateBotLeftContent = styled.div `
  max-width: 1260px;
  width: 100%;
  margin: 0 auto;
`;
const FakeBtn = styled.div `
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 140px;
  background: #EEF3FE;
  max-width: max-content;

  span {
    color: #0A49A5;
    text-align: center;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
  }
`;
const CreateBotH2 = styled.div `
  padding: 28px 0 96px;
  color: #070708;
  font-family: Poppins;
  font-size: 46px;
  font-style: normal;
  font-weight: 500;
  max-width: 760px;

  @media (max-width: 1160px) {
    line-height: 49px;
    font-size: 30px;
    max-width: 560px;
  };
`;
const CreateBotTextWrap = styled.div `
  padding-left: 50px;

  ul {
    list-style-type: disc;
    padding: 32px 18px 43px;

    li {
      color: #6886B0;
      font-family: "Public Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;

      span {
        color: #5179E0;
        font-family: "Public Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%; /* 25.6px */
      }

      @media (max-width: 1160px) {
        max-width: 500px;
      };

      @media (max-width: 980px) {
        max-width: 400px;
      };
    }

    @media (max-width: 1160px) {
      padding: 22px 18px 33px;
    };
  }

  @media (max-width: 1160px) {
    padding-left: 15px;
  };
`;
const CreateBotButton = styled.button `
  border-radius: 8px;
  background: var(--Color-Button-Blue, #0A49A5);
  display: inline-flex;
  padding: 16px 44px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  color: #FFF;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 105.556% */
  border: none;
  margin-bottom: 50px;
`;
const CreateBotParagraph = styled.div `
  color: #070708;
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
  max-width: 726px;

  @media (max-width: 1160px) {
    max-width: 526px;
  };
`;
const CreateBotRightContent = styled.div `
  display: grid;
  grid-template-columns: 1fr;

  img {
    &:last-child {
      object-fit: contain;
      width: 100%;
    }
    
    &:first-child {
      object-fit: contain;
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    display: none;
  };
`;
const WrapFirstImg = styled.div `
  align-self: flex-end;
  position: relative;
  z-index: 2;
  justify-self: center
`;
const WrapSecondImg = styled.div `
  position: absolute;
  bottom: 0;
  right: -79px;

  @media (max-width: 1024px) {
    right: -124px;
  };

  @media (max-width: 902px) {
    right: -156px;
  };

  @media (max-width: 805px) {
    right: -194px;
  };
`;
const Forms = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - 118px);
  height: 100%;
`;
const HeaderForm = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 34px;

  @media screen and (max-width: 468px) {
    padding: 16px 14px 34px;
    min-width: 370px;
  }
`;
const BackBtnStepForm = styled.button `
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  border: none;

  color: #070708;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;

  @media screen and (max-width: 468px) {
    gap: 6px;
  }
`;
const ListStepForm = styled.ul `
  display: flex;
  gap: 32px;

  @media screen and (max-width: 468px) {
    gap: 12px;
  }
`;
const ItemStepForm = styled.li `
  position: relative;
  color: ${({ $colorText }) => ($colorText ? '#0A49A5' : '#5179E0')};
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;

  @media screen and (max-width: 537px) {
    font-size: 13px;
  }

  @media screen and (max-width: 468px) {
    font-size: 10px;
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 6px;
    height: 6px;
    left: -18px;
    top: calc(50% - 3px);
    border-radius: 40px;
    background: ${({ $colorText }) => ($colorText ? '#0A49A5' : '#5179E0')};
  
    @media screen and (max-width: 468px) {
      width: 4px;
      height: 4px;
      left: -8px;
      top: calc(50% - 1px);
    }
  }

  &:first-child {
    &::before {
      content: '';
      display: none;
    }
  }
`;
const NextBtnStepForm = styled.button `
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #0A49A5;
  border: none;

  color: #FFF;
  text-align: center;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &:disabled {
    background: #bcd3f6;
  }
`;
const FormContainer = styled.div `
  position: relative;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #fff;
  padding-bottom: 80px;
`;
const FormWrap = styled.div `
  max-width: 620px;
  width: 100%;
  padding: 0 10px;
`;
export const S = {
    CreateBot,
    CreateBotContent,
    CreateBotLeftContent,
    CreateBotRightContent,
    WrapFirstImg,
    WrapSecondImg,
    FakeBtn,
    Wrapper,
    CreateBotWrapper,
    CreateBotH2,
    CreateBotParagraph,
    CreateBotTextWrap,
    CreateBotButton,
    Forms,
    HeaderForm,
    BackBtnStepForm,
    ListStepForm,
    ItemStepForm,
    NextBtnStepForm,
    FormContainer,
    FormWrap,
};
