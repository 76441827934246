import img1 from '../../assets/images/Personal-account-details/1.png';
import img2 from '../../assets/images/Personal-account-details/2.png';
import img3 from '../../assets/images/Personal-account-details/3.png';
import img4 from '../../assets/images/Personal-account-details/4.png';
import img5 from '../../assets/images/Personal-account-details/5.png';
import img6 from '../../assets/images/Personal-account-details/6.png';
import img7 from '../../assets/images/Personal-account-details/7.png';
import img8 from '../../assets/images/Personal-account-details/8.png';
export const data = [
    {
        images: [img1, img2],
        title: 'Accessible on Multiple Devices',
        desc: 'Enjoy consistent access to your account across all your devices—whether it’s your smartphone, tablet, or desktop. Your data syncs automatically, allowing you to switch between devices without missing a beat.',
    },
    {
        images: [img3],
        title: 'Complete Anonymity',
        desc: 'Not requiring a phone number during registration protects privacy by preventing links to your real identity, location tracking, and making it impossible to monitor your activities. You maintain complete anonymity since there\'s no way to trace your identity or physical location through carrier data.',
    },
    {
        images: [img4],
        title: 'Switch Between Accounts Effortlessly',
        desc: 'Manage multiple accounts with ease. Whether you’re juggling work and personal profiles or switching between different user identities, you can do so seamlessly without logging in and out.',
    },
    {
        images: [img5, img6],
        title: 'Channels are the future, websites are the past',
        desc: 'Ello lets you create channels in seconds with three options: free channels, revenue-generating premium channels, and private invitation-only channels. Free channels offer basic features, premium channels provide enhanced content for subscribers, and private channels deliver secure access for exclusive communities. This flexibility creates multiple revenue streams through public access, subscriptions, and exclusive content.',
    },
    {
        images: [img7],
        title: 'Groups to Interact with Friends',
        desc: 'Create or join groups where you can interact with like-minded people, share interests, and plan activities. Whether for socializing, collaborating, or organizing events, groups help keep everyone in sync.',
    },
    {
        images: [img8],
        title: 'Live Location Sharing',
        desc: 'Share your real-time location with friends or family members for coordinated meetups, added safety, or just staying in touch. You have full control over when and with whom you share your location.',
    },
];
