import styled from 'styled-components';
const Container = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-width: 370px;
`;
export const S = {
    Container,
};
