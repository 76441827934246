import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { S } from './HeaderForNavFooterStyle';
import BG from '../../assets/images/HeaderForNavFooter.svg';
export const HeaderForNavFooter = ({ title, subtitle }) => {
    const [img, setImg] = useState('');
    const navigate = useNavigate();
    const onGoBack = () => {
        navigate('/');
    };
    useEffect(() => {
        setImg(BG);
    }, []);
    return (_jsxs(S.HeaderForNavFooter, { "$bg": img, children: [_jsx(S.HeaderForNavFooterTitle, { children: title }), _jsx(S.HeaderForNavFooterSubtitle, { children: subtitle }), _jsx(S.HeaderForNavFooterTWrapSvg, { onClick: onGoBack, children: _jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "25", height: "24", viewBox: "0 0 25 24", fill: "none", children: [_jsx("path", { d: "M10.52 18.0698L4.45002 11.9998L10.52 5.92982", stroke: "#070708", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M21.4502 12L4.6202 12", stroke: "#070708", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" })] }) })] }));
};
