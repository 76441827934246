import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Parallax } from 'react-scroll-parallax';
import { navList } from '../../CONST/nav-list';
import { S } from './OnlineCoursesStyles';
import { S as Style } from '../PersonalAccounts/PersonalAccountsStyles';
import { TextContainer } from '../TextContainer/TextContainer';
import { ButtonsContainer } from '../ButtonsContainer/ButtonsContainer';
// import { S as StyleImg } from '../ListWrapper/ListWrapperStyles';
import img1 from '../../assets/images/online-curses/1.png';
import img2 from '../../assets/images/online-curses/2.png';
import img3 from '../../assets/images/online-curses/3.png';
import img4 from '../../assets/images/online-curses/4.png';
import img5 from '../../assets/images/online-curses/5.png';
import { ERoutesLink } from '../../CONST/routes-link';
const images = [img1, img2, img3, img4, img5];
const title = 'Creating or enrolling in masterclasses is fast and easy';
const text = 'Are you an industry expert or passionate creative? Turn your knowledge into profit! With our intuitive platform, crafting professional and captivating courses is a breeze. Start creating your masterclass today and open up new revenue streams!';
const OnlineCourses = () => (_jsxs(S.OnlineCoursesWrapper, { id: navList[6].toLowerCase().replace(' ', '_'), children: [_jsxs(Style.TextWrap, { children: [_jsx(TextContainer, { title: title, paragraph: text }), _jsx(ButtonsContainer
                // btnLeftName="Download"
                , { 
                    // btnLeftName="Download"
                    btnRightName: "Learn More", link: ERoutesLink.ONLINE_COURSES, children: _jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "18", height: "19", viewBox: "0 0 18 19", fill: "none", children: [_jsx("path", { d: "M10.8225 4.94727L15.375 9.49977L10.8225 14.0523", stroke: "#0A49A5", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M2.625 9.5H15.2475", stroke: "#0A49A5", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" })] }) })] }), _jsx(Parallax, { translateY: [-20, 60], scale: [0.8, 1.2], children: _jsx(S.ImagesWrapper, { children: images.map((image) => (_jsx("img", { src: image, alt: "Courses" }, image))) }) })] }));
export default OnlineCourses;
