import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { S } from './CreateBotStyles';
import { BotForm } from './BotForm';
import './index.scss';
export const FormsWrap = ({ isOpenForms, onCloseForms }) => {
    const [step, setStep] = useState(1);
    const [active, setActive] = useState(false);
    const [createAnother, setCreateAnother] = useState(false);
    const onStep = (num, operator) => {
        if (step - num === 0 && !operator) {
            onCloseForms();
        }
        else {
            setStep((prev) => (operator ? prev + num : prev - num));
        }
    };
    const resetAll = () => {
        setActive(true);
        setStep(1);
    };
    return (_jsxs(S.Forms, { children: [_jsxs(S.HeaderForm, { children: [createAnother ? _jsx("div", {}) : (_jsxs(S.BackBtnStepForm, { onClick: () => {
                            onStep(1, false);
                            setActive(false);
                        }, type: "button", children: [_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", children: [_jsx("path", { d: "M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z", stroke: "#0A49A5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M13.2583 15.5297L9.73828 11.9997L13.2583 8.46973", stroke: "#0A49A5", strokeLinecap: "round", strokeLinejoin: "round" })] }), _jsx("span", { children: "Back" })] })), _jsxs(S.ListStepForm, { children: [_jsx(S.ItemStepForm, { "$colorText": step >= 1, children: "Setup" }), _jsx(S.ItemStepForm, { "$colorText": step >= 2, children: `${isOpenForms.replace('_bot', '')} template` }), _jsx(S.ItemStepForm, { "$colorText": step >= 3, children: "Appearance" })] }), step === 3
                        ? _jsx("div", {})
                        : (_jsx(S.NextBtnStepForm, { disabled: active, onClick: () => onStep(1, true), children: "Next" }))] }), _jsx(S.FormContainer, { children: _jsx(S.FormWrap, { children: _jsx(BotForm, { step: step, onStep: onStep, setActive: setActive, resetAll: resetAll, setCreateAnother: setCreateAnother, createAnother: createAnother, isOpenForms: isOpenForms }) }) })] }));
};
