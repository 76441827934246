import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Parallax, useParallaxController } from 'react-scroll-parallax';
import { useLocation } from 'react-router-dom';
import { Financial } from '../../components/Financial/Financial';
import LatestPosts from '../../components/LatestPosts/LatestPosts';
import PaidChannels from '../../components/PaidChannels/PaidChannels';
import AiBotGenerates from '../../components/AiBotGenerates/AiBotGenerates';
import BusinessAccounts from '../../components/BusinessAccounts/BusinessAccounts';
import PersonalAccounts from '../../components/PersonalAccounts/PersonalAccounts';
import Groups from '../../components/Groups/Groups';
import OnlineCourses from '../../components/OnlineCourses/OnlineCourses';
import ElloPay from '../../components/ElloPay/ElloPay';
import { FirstBlock } from '../../components/FirstBlock/FirstBlock';
import { AdvancedBlock } from '../../components/AdvancedBlock/AdvancedBlock';
import { S } from './HomePageStyles';
const HomePage = () => {
    const { pathname } = useLocation();
    const parallaxController = useParallaxController();
    useEffect(() => {
        window.scrollTo(0, 0);
        const timeout = setTimeout(() => {
            if (parallaxController) {
                parallaxController.update();
            }
        }, 100);
        return () => clearTimeout(timeout);
    }, [pathname, parallaxController]);
    return (_jsxs("div", { style: { overflow: 'hidden' }, children: [_jsx(S.Container, { children: _jsx(FirstBlock, {}) }), _jsx(Parallax, { style: { marginTop: '-222px', background: '#fff' }, children: _jsx(AdvancedBlock, {}) }), _jsx(PersonalAccounts, {}), _jsx(BusinessAccounts, {}), _jsx(AiBotGenerates, {}), _jsx(PaidChannels, {}), _jsx(Groups, {}), _jsx(OnlineCourses, {}), _jsx(LatestPosts, {}), _jsx(ElloPay, {}), _jsx(Financial, {})] }));
};
export default HomePage;
