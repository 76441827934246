import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';
import { Successfully } from '../../ui/Successfully/Successfully';
import { addReport, getCategories, getCountries } from '../../services/supportService';
const FormSuport = () => {
    const [countries, setCountries] = useState([]);
    const [categories, setCategories] = useState([]);
    const [session, setSession] = useState('');
    const [data, setData] = useState({
        name: '',
        lastname: '',
        email: '',
        country_id: null,
    });
    const [currentStep, setCurrentStep] = useState(0);
    const [toastify, setToastify] = useState('');
    const onSession = (value) => {
        setSession(value);
    };
    const handleNextStep = (newData) => {
        setData(newData);
        setCurrentStep((prev) => prev + 1);
    };
    const resetForm = (message) => {
        setCurrentStep(0);
        setToastify(message);
        setTimeout(() => {
            setToastify('');
        }, 4000);
    };
    const onSubmitForm = async (values) => {
        // eslint-disable-next-line no-param-reassign
        delete values.file;
        try {
            const result = await addReport({
                reset: resetForm,
                request: {
                    data: {
                        ...data,
                        text: values.text,
                        username: values.username,
                        category_id: Number(values.category_id),
                        country_id: Number(data.country_id),
                        is_ello_user: true,
                    },
                    // 'h-captcha-response': values.captcha_code,
                    [session ? 'session' : 'h-captcha-response']: session || values.captcha_code,
                },
            });
            console.log(result);
        }
        catch (error) {
            console.log(error);
        }
    };
    const steps = [
        _jsx(StepOne, { next: handleNextStep, countries: countries }, 0),
        _jsx(StepTwo, { onSubmitForm: onSubmitForm, categories: categories, onSession: onSession }, 1),
    ];
    useEffect(() => {
        (async () => {
            try {
                // Завантаження країн
                const fetchedCountries = await getCountries();
                setCountries(fetchedCountries); // Зберігаємо країни в стейті
            }
            catch (error) {
                console.error('Failed to fetch countries:', error);
            }
            try {
                // Завантаження категорій
                const fetchedCategories = await getCategories();
                setCategories(fetchedCategories); // Зберігаємо категорії в стейті
            }
            catch (error) {
                console.error('Failed to fetch categories:', error);
            }
        })();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Successfully, { toastify: toastify }), steps[currentStep]] }));
};
export default FormSuport;
