import { jsx as _jsx } from "react/jsx-runtime";
import { S } from './ButtonsStyles';
import google from '../../../assets/images/google_btn.png';
import web from '../../../assets/images/web_app.png';
import apple from '../../../assets/images/app_store.png';
const socials = [
    { img: apple, href: 'https://apps.apple.com/us/app/ello-messenger/id6469151194' },
    { img: google, href: 'https://play.google.com/store/apps/details?id=com.beint.elloapp' },
    { img: web, href: 'https://web.ellomessenger.com/' },
];
export const Buttons = () => (_jsx(S.ButtonsWrap, { children: socials.map((social) => (_jsx("a", { href: social.href, rel: "noreferrer", target: "_blank", children: _jsx("img", { src: social.img, alt: "img" }) }, social.href))) }));
