import styled, { keyframes } from 'styled-components';
const fadeIn = keyframes `
  0% {
    opacity: 0;
    transform: translateY(-100px);
    /* width: 0; */
  }
  25% {
    opacity: 0.2;
    /* transform: translateY(-75px); */
    /* width: 25%;  */
  }
  50% {
    opacity: 0.5;
    /* transform: translateY(-50px); */
    /* width: 50%;  */

  }
  75% {
    opacity: 0.7;
    /* transform: translateY(-25px); */
    /* width: 75%;  */

  }
  100% {
    opacity: 1;
    transform: translateY(0);
    /* width: 100%;  */
  }
`;
export const S = {
    FreePaid: styled.section `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    h2 {
      align-self: start;

      color: #070708;
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      width: 100%;
    }
    /* @media (max-width: 535px) {
      flex-direction: column;
    }; */
  `,
    FirstBlock: styled.div `
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 80px;

    @media (max-width: 519px) {
      overflow: hidden;
      margin-top: 40px;
    };
  `,
    FirstBorder: styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 900px;
    height: 900px;
    flex-shrink: 0;
    border-radius: 900px;
    border: 2px solid rgba(12, 90, 204, 10%);

    @media (max-width: 1100px) {
      width: 700px;
      height: 700px;
      border-radius: 700px;
    };

    @media (max-width: 716px) {
      width: 500px;
      height: 500px;
      border-radius: 500px;
    };
  `,
    SecondBorder: styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 700px;
    height: 700px;
    flex-shrink: 0;
    border-radius: 700px;
    border: 2px solid rgba(12, 90, 204, 50%);
    @media (max-width: 1100px) {
      width: 500px;
      height: 500px;
      border-radius: 500px;
    };

    @media (max-width: 716px) {
      width: 300px;
      height: 300px;
      border-radius: 300px;
    };
  `,
    ThreeBorder: styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 500px;
    flex-shrink: 0;
    border-radius: 500px;
    border: 2px solid #0C5ACC;
    @media (max-width: 1100px) {
      width: 300px;
      height: 300px;
      border-radius: 300px;
    };

    @media (max-width: 716px) {
      width: 100px;
      height: 100px;
      border-radius: 100px;
    };
  `,
    FirstBlockTtile: styled.div `
    position: absolute;
    z-index: 2;
    bottom: 145px;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.97);
    padding: 100px 0;

    p {
      position: relative;
      animation: ${fadeIn} 3s ease-in-out;
      color: var(--Color-Text-Dark, #070708);
      font-family: Poppins;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      max-width: 1046px;
      text-align: center;
      margin: 0 auto;

      strong {
        color: var(--Color-Text-Dark, #070708);
        font-weight: 600;
      }

      @media (max-width: 1100px) {
        font-size: 24px;
        padding: 0 10px;
      };

      @media (max-width: 716px) {
        font-size: 18px;
        padding: 0 10px;
      };

      @media (max-width: 518px) {
        font-size: 13px;
      };
    }
    @media (max-width: 1100px) {
      padding: 50px 0;
    };

    @media (max-width: 716px) {
      padding: 20px 0;
    };
  `,
    FirstBlockImgLeft: styled.div `
    position: absolute;
    left: calc(50% - 542px);
    top: 125px;
    @media (max-width: 1100px) {
      max-width: 150px;
      left: calc(50% - 352px);
    };

    @media (max-width: 716px) {
      max-width: 100px;
      left: calc(50% - 232px);
    };
  `,
    FirstBlockImgCenter: styled.div `
    position: absolute;
    overflow: hidden;
    right: calc(50% - 150px);
    width: 300px;
    top: 0;
    
    img {
      padding: 2px 10px 0 10px;
      object-fit: contain;
      width: 100%;
      border-radius: 41px;

      @media (max-width: 716px) {
        padding: 5px;
        border-radius: 28px;
      };
    }

    @media (max-width: 1100px) {
      width: 250px;
      right: calc(50% - 123px);
    };

    @media (max-width: 716px) {
      width: 200px;
      right: calc(50% - 101px);
    };
  `,
    FirstBlockImgRight: styled.div `
    position: absolute;
    left: calc(50% + 305px);
    top: 125px;

    @media (max-width: 1100px) {
      max-width: 150px;
      left: calc(50% + 202px);
    };

    @media (max-width: 716px) {
      width: 100px;
      left: calc(50% + 135px);
    };
  `,
    Container: styled.div `
    padding: 10px;
    background-image: url(${({ $bg }) => $bg});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    @media (max-width: 716px) {
      padding: 5px;
    };
  `,
    SecondBlock: styled.div `
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: -145px;
    background: rgba(255, 255, 255, 0.97);
    width: 100%;
  `,
    SecondBlockContent: styled.div `
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    max-width: 1260px;
    ${({ $reverse }) => $reverse}

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: center;
      gap: 50px;
      margin-top: 30px;
    };
  `,
    SecondBlockContentRight: styled.div `
    display: flex;
    align-items: center;
    width: 100%;
    img {
      margin-right: 41px;

      &:last-child {
        margin-right: 0;

        @media (max-width: 1154px) {
          object-fit: contain;
          /* width: 100%; */
          max-width: 159px;
        };

        @media (max-width: 380px) {
          max-width: 129px;
        };
      }

      @media (max-width: 1154px) {
        object-fit: contain;
        /* width: 100%; */
        max-width: 209px;
      };

      @media (max-width: 452px) {
        max-width: 179px;
      };

      @media (max-width: 382px) {
        max-width: 149px;
      };
    }

    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
    };
  `,
    SecondBlockContentLeft: styled.div `
    width: auto;
    ${({ $displayFlex }) => $displayFlex}
    width: 90%;
  `,
    GreenText: styled.div `
    color: #44BE2E;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    text-transform: uppercase;
    margin-bottom: 8px;

    @media (max-width: 1154px) {
      font-size: 13px;
    };
  `,
    TitleList: styled.div `
    position: relative;
    color: var(--Color-Text-Dark, #070708);
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 44.8px */

    &::after {
      content: '';
      position: absolute;
      bottom: -20px;
      display: block;
      width: 16px;
      height: 2px;
      background: #070708;
    }

    @media (max-width: 1154px) {
      font-size: 26px;
    };

    @media (max-width: 846px) {
      /* max-width: 327px; */
    };
  `,
    SubTitleList: styled.div `
    margin-top: 40px;
    color: #070708;
    font-family: "Public Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */

    @media (max-width: 1154px) {
      font-size: 14px;
    };
  `,
    List: styled.ul `
    width: 100%;
    position: relative;
    padding-left: 20px;
    padding-top: 40px;
  `,
    Item: styled.li `
    position: relative;
    width: 100%;
    padding-bottom: 8px;

    &:last-child {
      padding-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -17px;
      transform: translateY(-50%);
      width: 6px;
      height: 6px;
      background-color: #F90;
      border-radius: 50%;
    }

    strong {
      color: #070708;
      font-family: "Public Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 25.2px */

      @media (max-width: 1154px) {
        font-size: 14px;
      };
    }

    @media (max-width: 1154px) {
      font-size: 14px;
    };
  `,
    KeyFeaturesWrap: styled.div `
    display: flex;
    justify-content: center;
    width: 100%;
  `,
    KeyFeatures: styled.div `
    width: 100%;
    max-width: 1260px;
  `,
    KeyFeaturesList: styled.div `
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 15px;

    @media (max-width: 1024px) {
      flex-wrap: wrap;
    };

    @media (max-width: 768px) {
      display: flex !important;
      flex-wrap: wrap !important;
    };
    ${({ $grid }) => $grid}
  `,
    KeyFeaturesItem: styled.div `
    width: 100%;
    max-width: 260px;
    @media (max-width: 1024px) {
      min-width: 175px;
    };

    @media (max-width: 555px) {
      min-width: 100%;
    };
  `,
    Square: styled.div `
    width: 44px;
    height: 44px;
    background-color: #F0F6FF;
    margin-bottom: 28px;
  `,
    KeyFeaturesTitle: styled.div `
    color: #070708;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 21.6px */
    margin-bottom: 12px;
  `,
    KeyFeaturesSubTitle: styled.div `
    color: #4D4D50;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  `,
    Wrapper: styled.div `
    display: flex;
    justify-content: center;
    padding: 120px 0 180px;
    border-radius: 24px;
    width: 100%;

    @media (max-width: 1024px) {
      padding: 0 0 90px;
    };
  `,
    Inner: styled.div `
    overflow: hidden;
    display: flex;
    width: 100%;
    max-width: 1260px;
    background: #F0F6FF;
    border-radius: 24px;
    padding: 77px 0 0 107px;
    @media (max-width: 1024px) {
      flex-direction: column-reverse;
      align-items: center;
      padding: 77px 10px;
    };
  `,
    Left: styled.div `
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    h3 {
      color: #070708;
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 44.8px */
      margin-bottom: 16px;
    }

    p {
      color: #070708;
      font-family: "Public Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 25.2px */
      margin-bottom: 44px;
    }

    @media (max-width: 1024px) {
      padding-top: 50px;
      background: #F0F6FF;
      align-items: center;
    };
  `,
    Right: styled.div `
    display: flex;
    justify-content: center;
    width: 100%;
    /* overflow: hidden; */
    position: relative;
  `,
    ImgWrap: styled.div `
    position: relative;
    width: 307px;
    background: url(${({ $bg }) => $bg});
    padding: 12px 12px 0 12px;
    bottom: 0;
    /* left: calc(50% - 100px); */

    img {
      object-fit: contain;
      width: 100%;
    }
  `,
    ImgWrapSmallOne: styled.div `
    position: absolute;
    z-index: 5;
    ${({ $position }) => $position}

    img {
      object-fit: contain;
      width: 100%;
    }
  `,
    ImgWrapSmallTwo: styled.div `
    position: absolute;
    ${({ $position }) => $position}
    z-index: 5;

    img {
      object-fit: contain;
      width: 100%;
    }
  `,
    CircleOne: styled.div `
    position: absolute;
    bottom: -234px;
    right: -88px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 681px;
    height: 681px;
    border-radius: 681px;
    border: 2px solid rgba(12, 90, 204, 25%);

    @media (max-width: 1024px) {
      bottom: auto;
      right: auto;
    };
  `,
    CircleTwo: styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 529px;
    height: 529px;
    border-radius: 529px;
    border: 2px solid rgba(12, 90, 204, 50%);

    @media (max-width: 1024px) {
      bottom: auto;
      right: auto;
    };
  `,
    CircleThree: styled.div `
    width: 378.333px;
    height: 378.333px;
    border-radius: 378.333px;
    border: 2px solid #0C5ACC;

    @media (max-width: 1024px) {
      bottom: auto;
      right: auto;
    };
  `,
};
