import { jsx as _jsx } from "react/jsx-runtime";
import { S } from './ButtonStyle';
import apple from '../../assets/images/free&chanel/appStore.png';
import google from '../../assets/images/free&chanel/google.png';
import web from '../../assets/images/free&chanel/web.png';
const socials = [
    { img: apple, href: 'https://apps.apple.com/us/app/ello-messenger/id6469151194' },
    { img: google, href: 'https://play.google.com/store/apps/details?id=com.beint.elloapp' },
    { img: web, href: 'https://web.ellomessenger.com/' },
];
export const Buttons = () => (_jsx(S.ButtonsWrapp, { children: socials.map((social) => (_jsx("a", { href: social.href, rel: "noreferrer", target: "_blank", children: _jsx("img", { src: social.img, alt: "social icon" }) }, social.href))) }));
