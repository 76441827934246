import styled from 'styled-components';
export const S = {
    PopupWrap: styled.div `
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(27, 46, 59, 0.80);
    backdrop-filter: blur(4px);
  `,
    Popup: styled.div `
    position: relative;
    display: inline-flex;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 44px;
    border-radius: 10px;
    background: var(--BG-Primary, #FFF);
    box-shadow: 0px 20px 80px 0px rgba(0, 0, 0, 0.08);
    margin: 0 10px;

    h2 {
      color: #070708;
      text-align: center;
      font-family: "Public Sans";
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 39.2px */
    }
  `,
    PopupClose: styled.div `
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  `,
    PopupWrapBtn: styled.div `
    display: flex;
    gap: 8px;

    button {
      padding: 24px 48px;
      border-radius: 16px;
      border: 1px solid var(--Color-Bg-Grey, #EEEEEF);
      outline: none;

      &:hover {
        border-radius: 16px;
        border: 1px solid #6C63FF;
      }

      
      @media (max-width: 583px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      };
    }

    span {
      display: block;
      margin-top: 24px;
      color: #070708;
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 180%; /* 28.8px */
    }

    @media (max-width: 583px) {
      flex-direction: column;
      width: 100%;
    };
  `,
};
