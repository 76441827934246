import styled from 'styled-components';
export const S = {
    SupportRightContent: styled.div `
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 80px 1fr;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 15px;
    height: 100%;
    min-height: calc(100vh - 62px);
    padding-bottom: 20px;

    @media screen and (max-width: 1024px) {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  `,
    SupportHeader: styled.div `
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      background-color: transparent;
    }
  `,
    SupportFooter: styled.div `
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px 0;

    img {
      margin-left: 32px;
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      gap: 15px;
    }
  `,
    SupportFooterList: styled.ul `
    display: flex;
    gap: 50px;
    list-style-type: none;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 15px;
      text-align: center;
    }
  `,
    SupportFooterItem: styled.li `
    cursor: pointer;
    background: transparent;

    > a {
      color: var(--text-main-black, #070708);
      text-align: center;
      font-family: "Public Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      background: transparent;
      text-decoration: none;
    }
  `,
};
