import { createGlobalStyle } from 'styled-components';
// CSS Reset
export const GlobalStyle = createGlobalStyle `
  @font-face {
    font-family: 'Public Sans';
    src: url('/fonts/Public_Sans/static/PublicSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Public Sans';
    src: url('/fonts/Public_Sans/static/PublicSans-Medium.ttf') format('truetype');
    font-weight: 500; /* Medium weight */
    font-style: normal;
  }

  @font-face {
    font-family: 'Public Sans';
    src: url('/fonts/Public_Sans/static/PublicSans-Bold.ttf') format('truetype');
    font-weight: 700; /* Bold weight */
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
    font-weight: 500; /* Medium weight */
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
    font-weight: 700; /* Bold weight */
    font-style: normal;
  }

  body {
    font-family: 'Public Sans', sans-serif;
  }

  #root {
    min-height: 100%;
  }

  /* Reset CSS */
  *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font: inherit;
    background: transparent;
  }

  html, body {
    height: 100%;
    font-size: 100%;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* html {
    scroll-behavior: smooth;
  } */

  h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var,
  b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption,
  tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure,
  figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* Set default styles for elements */
  ol, ul {
    list-style: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  img {
    max-width: 100%;
    display: block;
  }

  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;
