import { useEffect } from 'react';
const useOutsideClick = (ref, handler) => {
    const listener = (event) => {
        const target = event.target;
        if (ref.current && !ref.current.contains(target)) {
            handler();
        }
    };
    useEffect(() => {
        document.addEventListener('click', listener);
        return () => {
            document.removeEventListener('click', listener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
export default useOutsideClick;
