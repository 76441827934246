import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { S } from './PopupStyle';
// import docBot from '../../assets/images/doc-bot.png';
import textBot from '../../assets/images/text-bot.png';
import imgBot from '../../assets/images/img-bot.png';
const data = [
    // { text: 'Document bot', img: docBot },
    { text: 'Text bot', img: textBot },
    { text: 'Image bot', img: imgBot },
];
export const Popup = ({ isOpen, onClose, forForms }) => {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen]);
    if (!isOpen)
        return null;
    return (_jsx(S.PopupWrap, { children: _jsxs(S.Popup, { onClick: (e) => e.stopPropagation(), children: [_jsx(S.PopupClose, { onClick: onClose, children: _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "22", height: "23", viewBox: "0 0 22 23", fill: "none", children: _jsx("path", { d: "M16.5551 16.6955L5.72618 5.8666M5.72618 16.6955L16.5551 5.8666", stroke: "#929298", strokeLinecap: "round" }) }) }), _jsx("h2", { children: "What type of AI bot you want to create?" }), _jsx(S.PopupWrapBtn, { children: data.map((btn) => (_jsxs("button", { name: btn.text.replaceAll(' ', '_'), type: "button", onClick: forForms, children: [_jsx("img", { src: btn.img, alt: "" }), _jsx("span", { children: btn.text })] }, btn.text))) })] }) }));
};
