import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Parallax, useParallaxController } from 'react-scroll-parallax';
import { useParams } from 'react-router-dom';
import { S as Styles } from '../AdvancedEncryption/AdvancedEncryptionStyle';
import { S } from './PersonalAccountsStyles';
// import { CylinderButton } from '../../components/CylinderButton/CylinderButton';
import { TextContainer } from '../../components/TextContainer/TextContainer';
import { Accounts } from '../../components/Accounts/Accounts';
import { data } from '../../components/Accounts/data';
import { Content } from '../../components/Content/Content';
import ello from '../../assets/images/Personal-account-details/ello.png';
import phone from '../../assets/images/Personal-account-details/phone.png';
import bigPhoto from '../../assets/images/Personal-account-details/big1.png';
const title = 'Personal accounts';
const text = 'Your personal account is more than just a profile—it’s your gateway to a tailored experience that puts your needs first. Store and manage your personal information, effortlessly connect with friends and family, and customize services to suit your preferences.';
const title2 = 'When to Use a Personal Account?';
const description2 = 'A Personal Account is ideal when you want to use Ello as an individual for private and casual interactions. This type of account lets you connect with friends and family, join channels that interest you, and engage with content on a personal level.';
const title3 = 'Private or public Account?';
const description3 = 'A Private Account on Ello restricts content visibility to approved followers only, ideal for users prioritizing privacy and personal sharing with close contacts. A Public Account allows anyone to view and interact with your content, perfect for networking and building an audience.';
export const PersonalAccounts = () => {
    const params = useParams();
    const parallaxController = useParallaxController();
    useEffect(() => {
        window.scrollTo(0, 0);
        const timeout = setTimeout(() => {
            if (parallaxController) {
                parallaxController.update();
            }
        }, 100);
        return () => clearTimeout(timeout);
    }, [params, parallaxController]);
    return (_jsxs(Styles.Wrapper, { children: [_jsx(Styles.Container, { children: _jsx(Styles.Inner, { children: _jsx(TextContainer, { title: title, paragraph: text }) }) }), _jsx(Styles.BG, { "$bg": ello, style: { padding: 0, height: '430px', position: 'relative' }, children: _jsx(S.Image, { children: _jsx("img", { src: phone, alt: "phones" }) }) }), _jsx(S.H2, { children: "Key Features:" }), _jsxs(Styles.Container, { style: { padding: '0 10px', gap: '40px', margin: '0 10px 140px' }, children: [data.map((item, index) => (_jsx(Parallax, { style: { width: '100%' }, scale: [0.2 + index * 0.1, 1], children: _jsx(Accounts, { desc: item.desc, title: item.title, images: item.images }) }, item.title))), _jsx(S.Wrap, { children: _jsx(Content, { flexReverse: { flexDirection: 'row-reverse' }, title: title2, desc: description2, photo: bigPhoto }) }), _jsx(S.Wrap, { children: _jsx(Content, { title: title3, desc: description3, photo: bigPhoto }) })] })] }));
};
