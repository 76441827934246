import styled from 'styled-components';
export const S = {
    StayUpToDay: styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `,
    StayUpToDayInner: styled.div `
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;
  `,
    StayUpToDayFirstBox: styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 750px;
    height: 750px;
    flex-shrink: 0;
    border-radius: 900px;
    border: 2px solid rgba(12, 90, 204, 10%);
    margin: -10px 0 0;
  `,
    StayUpToDaySecondBox: styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 550px;
    height: 550px;
    flex-shrink: 0;
    border-radius: 700px;
    border: 2px solid rgba(12, 90, 204, 50%);
  `,
    StayUpToDayThirdBox: styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 350px;
    height: 350px;
    flex-shrink: 0;
    border-radius: 500px;
    border: 2px solid #0C5ACC;
  `,
    StayUpToDayImgBox: styled.div `
    position: absolute;
    bottom: 75px;
    left: calc(50% - 155px);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  `,
};
