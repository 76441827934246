import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { CountrySelect } from '../../ui/Countries/Countries';
import { ELengthName, ERoutesLink } from '../../CONST/routes-link';
import Input from '../../ui/Formik/Input';
import Checkbox from '../../ui/Formik/Checkbox/Checkbox';
const initialValue = {
    name: '',
    lastname: '',
    email: '',
    country_id: null,
};
const validationSchema = Yup.object().shape({
    name: Yup.string()
        .matches(/^[a-zA-Z\s]+$/, 'There must be only letters of the English alphabet!')
        .min(ELengthName.MIN, `First name should be minimum ${ELengthName.MIN}`)
        .max(ELengthName.MAX, `First name should be maximum ${ELengthName.MAX}`)
        .required('Name is required'),
    lastname: Yup.string()
        .matches(/^[a-zA-Z\s]+$/, 'There must be only letters of the English alphabet!')
        .min(ELengthName.MIN, `Last surname should be minimum ${ELengthName.MIN}`)
        .max(ELengthName.MAX, `Last surname should be maximum ${ELengthName.MAX}`)
        .required('Last name is required'),
    email: Yup.string()
        .trim()
        .required('Please, enter your email!')
        .email('Invalid email format'),
    country_id: Yup.string().required('Country is required'),
});
export const StepOne = ({ next, countries }) => {
    const [check, setCheck] = useState(false);
    const onChackBox = (event) => {
        const { checked } = event.target;
        setCheck(checked);
    };
    return (_jsx(Formik, { initialValues: initialValue, validationSchema: validationSchema, onSubmit: (values, { resetForm }) => {
            next(values);
            resetForm();
        }, children: ({ values, setFieldValue, errors }) => {
            // const onActiveCaptcha = (value: boolean) => {
            //   setFieldValue('captcha_code', '000000')
            // }
            const onAddCoutry = (value) => {
                setFieldValue('country_id', value);
            };
            return (_jsx(Form, { className: "", children: _jsx("div", { className: "form-container", children: _jsxs("div", { className: "form-inner form-inner-width", children: [_jsxs("div", { className: "support__header-form", children: [_jsx("div", { className: "support__header-form-title", children: "Connect with Support" }), _jsx("div", { className: "input-label-name margin-none", children: "Step 1 of 2" })] }), _jsx(Field, { name: "name", component: Input, 
                                // label={t('First name')}
                                labelName: "First name", value: values.name }), _jsx(Field, { name: "lastname", type: "text", component: Input, 
                                // label={t('Last name')}
                                labelName: "Last name", value: values.lastname }), _jsx(Field, { type: "email", name: "email", component: Input, 
                                // label={t('Email')}
                                labelName: "Email", value: values.email }), _jsx(Field, { type: "text", name: "country_id", component: CountrySelect, addCountry: onAddCoutry, countriesList: countries, label: "Country", labelName: "Country" }), _jsxs("div", { className: "support__chekbox", children: [_jsx(Field, { name: "policy", component: Checkbox, chackBox: onChackBox }), _jsxs("span", { children: ["By sending this request you agree to our", ' ', _jsx(Link, { to: ERoutesLink.PRIVACY_POLICY, target: "_blank", rel: "noopener noreferrer", children: "Privacy Policy" })] })] }), _jsx("div", { className: "form-submit", children: _jsx("button", { className: classNames('support__btn', {
                                        disabled: Object.entries(errors).length || !check,
                                    }), type: "submit", children: "Next" }) })] }) }) }));
        } }));
};
