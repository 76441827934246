/* eslint-disable no-use-before-define */
/* eslint-disable class-methods-use-this */
import axios from 'axios';
import onError from './onError';
export const API_BASE_URL = process.env.NODE_ENV === 'production' ? 'https://ext-support.ellomessenger.com' : 'https://ext-support-stage.ellomessenger.com';
class RequestService {
    get = (url, params, isAuthRequired = true, contentType = 'application/json') => createRequest('GET', url, null, isAuthRequired, contentType, params);
    post = (url, 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body, isAuthRequired = true, contentType = 'application/json') => createRequest('POST', url, body, isAuthRequired, contentType);
    multipart = (url, 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body, isAuthRequired = true, contentType = 'application/json') => createRequest('POST', url, body, isAuthRequired, contentType);
    put = (url, 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body, isAuthRequired = true, contentType = 'application/json') => createRequest('PUT', url, body, isAuthRequired, contentType);
    delete = (url, isAuthRequired = true, contentType = 'application/json') => createRequest('DELETE', url, null, isAuthRequired, contentType);
}
const createRequest = (method, url, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
body, isAuthRequired, contentType, params) => {
    const config = {
        method,
        url: API_BASE_URL + url,
        data: body,
        params,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        headers: setHeader(isAuthRequired, contentType),
    };
    return axios(config);
    // .then((response) => response.data)
    // .catch((error: IAxiosError) => {
    //   store.dispatch(resetAuthState());
    // });
};
const setHeader = (isAuthRequired, contentType) => {
    if (isAuthRequired) {
        axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token') || undefined}`;
    }
    else {
        delete axios.defaults.headers.common.Authorization;
    }
    axios.defaults.headers.common['Content-Type'] = contentType;
};
axios.interceptors.response.use((response) => response, onError);
export default new RequestService();
