import styled from 'styled-components';
export const S = {
    ButtonsWrapp: styled.section `
    display: flex;
    justify-content: flex-start;
    gap: 8px;

    button {
      border-radius: 8px;
      background: #2F2F2F;
      padding: 8px 32px;
      border: none;
      
      @media (max-width: 452px) {
        width: 100%;
        display: flex;
        justify-content: center;
      };
    }

    @media (max-width: 452px) {
      flex-direction: column;
      width: 100%;
      align-items: center;
      justify-content: center;
    };

    a {
      border-radius: 8px;
      background: #2F2F2F;
      padding: 8px 32px;
    }
  `,
};
