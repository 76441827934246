import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Files from 'react-files';
export const FileDropzone = ({ title, onGetFile, infoSize, listFormatInfo, SvgComponent, nameBtn, }) => {
    const [nameImg, setNameImg] = useState('');
    const handleChange = (files) => {
        setNameImg(files[0].name);
        onGetFile(files[0], title.includes('logo'));
    };
    const handleError = (error, file) => {
        console.log(`error code ${error.code}: ${error.message}`);
        console.log(file);
    };
    return (_jsx("div", { className: "files", children: _jsxs(Files, { className: "files-dropzone", onChange: handleChange, onError: handleError, accepts: ['image/png', '.pdf', 'audio/*'], multiple: false, maxFileSize: 10000000, minFileSize: 0, clickable: true, children: [nameImg || (SvgComponent && _jsx(SvgComponent, {})), _jsx("h4", { children: title }), infoSize && _jsx("p", { children: infoSize }), listFormatInfo && _jsx("p", { children: listFormatInfo }), nameBtn && _jsx("button", { type: "button", children: nameBtn })] }) }));
};
