import styled, { keyframes } from 'styled-components';
const spin = keyframes `
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
export const Loading = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    animation: ${spin} 1s linear infinite; /* Анімація обертання */
  }
`;
