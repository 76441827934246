import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useParallaxController } from 'react-scroll-parallax';
import { S } from './FinancialStyles';
import { FirstBlock } from './FirstBlock/FirstBlock';
import { S as StyleBg } from '../../pages/AdvancedEncryption/AdvancedEncryptionStyle';
import { ContentBox } from '../../components/AiBotContentBox/ContentBox';
import bg from '../../assets/images/Business-Accounts-details/Frame 1000002897.png';
import img2 from '../../assets/images/Financial/details/2.png';
import img3 from '../../assets/images/Financial/details/3.png';
import img4 from '../../assets/images/Financial/details/4.png';
import img5 from '../../assets/images/Financial/details/5.png';
import { WhyChooseElloChannelsBlock } from '../../components/FreePaidComponent/WhyChooseElloChannelsBlock/WhyChooseElloChannelsBlock';
import { Chart } from './Chart/Chart';
const photos = [img2, img3, img4];
const paragraph = [
    `
    Access your financial information anytime, anywhere. Our
    intuitive dashboard allows you to view and manage
    everything from payments and transfers to subscriptions and
    investments—all in one place. Stay organized and keep track
    of your financial activities effortlessly.
  `,
];
const Financial = () => {
    const params = useParams();
    const parallaxController = useParallaxController();
    useEffect(() => {
        window.scrollTo(0, 0);
        const timeout = setTimeout(() => {
            if (parallaxController) {
                parallaxController.update();
            }
        }, 100);
        return () => clearTimeout(timeout);
    }, [params, parallaxController]);
    return (_jsxs(S.Financial, { children: [_jsx(FirstBlock, {}), _jsx(S.FinancialDesc, { style: { padding: '0 10px' }, children: _jsxs("p", { children: ["Simplify Your Financial Journey.", _jsx("strong", { children: " Managing your finances" }), ' ', "has never been easier. Our platform brings all your financial transactions into one seamless experience, allowing you to take control of your payments, transfers, and investments in a secure and convenient space."] }) }), _jsx(StyleBg.BG, { "$bg": bg, children: _jsx(ContentBox, { flexDirection: { flexDirection: 'row-reverse' }, title: "AI chat powered by OpenAI", paragraph: paragraph, photos: photos }) }), _jsx(Chart, {}), _jsx("div", { style: { width: '100%', background: '#fff' }, children: _jsx(WhyChooseElloChannelsBlock, { title: "Get Started Today", mainImg: img5, paragraphOne: `
            Join thousands of users already enjoying the
            convenience of managing all their financial
            transactions in one place. Sign up today and
            experience hassle-free financial management
            like never before.
          ` }) })] }));
};
export default Financial;
