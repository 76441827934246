import styled from 'styled-components';
export const S = {
    ButtonsWrap: styled.div `
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 44px;
    padding: 0 10px;

    @media (max-width: 535px) {
      flex-direction: column;
    };

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background: #2F2F2F;
      padding: 8px 32px;
    }
  `,
    Padding: {
        padding: '8px 32px',
    },
};
