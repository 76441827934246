import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import img1 from '../../../assets/images/accout-delete/1.svg';
import { S } from '../../../pages/DeleteAccounts/DeleteAccountsStyle';
export const HowToDelete = () => {
    const [image, setImage] = useState('');
    useEffect(() => {
        setImage(img1);
    }, []);
    return (_jsxs(S.AccountBoxFirst, { children: [_jsxs("div", { className: "account__box-content", children: [_jsxs(S.AccountTitleH1, { children: ["How to Delete Your", ' ', _jsx("br", {}), ' ', _jsx("span", { children: "Ello Messenger" }), "Account"] }), _jsx(S.AccountParagraph, { children: "If you've decided to delete your Ello Messenger account, please follow the steps outlined below. This process is designed to ensure that all relevant actions are taken care of before your account is permanently deleted." }), _jsxs(S.AccountParagraph, { children: ["Note that once you delete your account,", ' ', _jsx("span", { children: "all user data will be deleted forever" }), ", and it cannot be recovered."] })] }), _jsx("div", { children: _jsx(S.AccountImg, { src: image, alt: "" }) })] }));
};
