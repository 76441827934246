export var ERoutesLink;
(function (ERoutesLink) {
    ERoutesLink["HOME"] = "/";
    ERoutesLink["ADVANCED_ENCRYPTION"] = "/advanced-encryption";
    ERoutesLink["PERSONAL_ACCOUNTS"] = "/personal-accounts";
    ERoutesLink["BUSINESS_ACCOUNTS"] = "/business-accounts";
    ERoutesLink["AI_BOT"] = "/ai-bot";
    ERoutesLink["ELLO_PAY"] = "/ello-pay";
    ERoutesLink["FREE_PAY"] = "/free-pay";
    ERoutesLink["GROUPS"] = "/groups";
    ERoutesLink["ONLINE_COURSES"] = "/online-courses";
    ERoutesLink["STAY_UP_TO_DAY"] = "/stay-up-to-day";
    ERoutesLink["FINANCIAL"] = "/financial";
    ERoutesLink["CREATE_BOT"] = "/create-bot";
    ERoutesLink["TEST"] = "/test";
    ERoutesLink["PRIVACY_POLICY"] = "/privacy-policy";
    ERoutesLink["COOKIE_POLICY"] = "/cookie-policy";
    ERoutesLink["TERMS"] = "/terms";
    ERoutesLink["AI_TERMS"] = "/ai-terms";
    ERoutesLink["FAQ"] = "/FAQ";
    ERoutesLink["DELETE_ACCOUNT"] = "/delete-account";
    ERoutesLink["SUCCESS"] = "/success";
    ERoutesLink["ERROR"] = "/error";
    ERoutesLink["SUPPORT"] = "/support";
    ERoutesLink["MANUALS"] = "/manuals";
    ERoutesLink["COMMUNITY_GUIDELINES"] = "/community-guidelines";
})(ERoutesLink || (ERoutesLink = {}));
export var ELengthName;
(function (ELengthName) {
    ELengthName[ELengthName["MIN"] = 2] = "MIN";
    ELengthName[ELengthName["MAX"] = 30] = "MAX";
})(ELengthName || (ELengthName = {}));
