export const data = [
    {
        title: 'End-to-End Encryption:',
        desc: `
      Ensures only intended recipients can read messages
    `,
    },
    {
        title: 'Forward Secrecy:',
        desc: `
      Protects past communications even if future sessions
      are compromised
    `,
    },
    {
        title: 'Layered Security Structure:',
        desc: `
      Combines symmetric and asymmetric cryptography for robust protection
    `,
    },
    {
        title: 'Resilience Against Attacks:',
        desc: `
      Built-in protections against common network threats
    `,
    },
    {
        title: 'Efficiency and Speed:',
        desc: `
      Optimized for real-time messaging on both stable and unstable networks
    `,
    },
];
