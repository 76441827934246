import styled from 'styled-components';
const Terms = styled.div `
  max-width: 1137px;
  margin: 0 auto;
  padding: 0 10px;
`;
const Wrapper = styled.div `
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  // padding: 0 35px;
  min-height: 100vh;
  min-width: 350px;

  @media screen and (width <= 768px) {
    // padding: 0 10px;
  }
`;
const TermsSubtitle = styled.p `
  color: var(--text-secondary-light, #74747b);
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 21.6px */

  a {
    background: transparent;
    text-decoration: underline;
  }
`;
const TermsTitleH3 = styled.h3 `
  color: var(--text-main-black, #070708);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 100% */
  letter-spacing: 0.18px;
`;
export const S = {
    Wrapper,
    Terms,
    TermsSubtitle,
    TermsTitleH3,
};
