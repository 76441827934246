import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useParallaxController } from 'react-scroll-parallax';
import { S } from './OnlineCoursesStyles';
import { Title } from './Title';
import img1 from '../../assets/images/online-curses/1.png';
import img2 from '../../assets/images/online-curses/2.png';
import img3 from '../../assets/images/online-curses/3.png';
import img4 from '../../assets/images/online-curses/4.png';
import img5 from '../../assets/images/online-curses/5.png';
import img6 from '../../assets/images/online-curses/6.png';
import img7 from '../../assets/images/online-curses/7.png';
import img8 from '../../assets/images/online-curses/8.png';
import img9 from '../../assets/images/online-curses/9.png';
import img10 from '../../assets/images/online-curses/10.png';
import bg from '../../assets/images/free&chanel/2.png';
import { SecondBlock } from '../../components/FreePaidComponent/SecondBlock/SecondBlock';
import KeyFeatures from '../../components/FreePaidComponent/KeyFeatures/KeyFeatures';
import { Functionality } from '../Groups/Functionality';
import { StyleGroup } from '../Groups/GroupsStyles';
import { WhyChooseElloChannelsBlock } from '../../components/FreePaidComponent/WhyChooseElloChannelsBlock/WhyChooseElloChannelsBlock';
const greenTextStudents = 'For Students';
const titleListStudents = 'Ello opens the door to a world<br/> of learning opportunities<br/> tailored to your needs and<br/> interests.';
const subTitleListStudents = `
  Whether you're enhancing your skills, exploring new subjects,<br/>
  or pursuing your passions, Ello offers flexible courses you can<br/>
  access anytime, anywhere. Engage with interactive lessons,<br/>
  connect with expert instructors, and collaborate with peers in<br/>
  a supportive and dynamic environment. With Ello, learning<br/>
  becomes more than just acquiring knowledge—it’s an<br/>
  experience that inspires growth and curiosity.
`;
const imageList = [img6, img7];
const greenTextStudentsTwo = 'From Creating Content';
const titleListStudentsTwo = `
  Ello empowers educators with<br/>
  intuitive tools to design and<br/>
  deliver impactful courses<br/>
  effortlessly.
`;
const subTitleListStudentsTwo = `
  Build engaging lessons, incorporate multimedia elements,<br/>
  and structure your content with ease, all from a user-friendly<br/>
  platform. Whether you’re teaching a small group or reaching<br/>
  a global audience, Ello equips you with everything needed to<br/>
  create a compelling and professional learning experience.<br/>
  Focus on sharing your expertise while Ello takes care of the<br/>
  rest.
`;
const imageListTwo = [img8, img9];
const list = [
    { title: 'Effortless Course Creation:', subtitle: 'Use our user-friendly tools to design and launch courses in just a few clicks. Add videos, documents, quizzes, and more to create engaging learning experiences.' },
    { title: 'Easy Enrollment:', subtitle: 'Students can discover and join courses instantly. With simple navigation and clear instructions, starting a new learning journey is hassle-free.' },
    { title: 'Interactive Learning:', subtitle: 'Engage with students and instructors in real time through live chats, Q&A sessions, and interactive group discussions.' },
    { title: 'Flexible Access:', subtitle: 'Learn or teach from anywhere, on any device. Ello ensures that courses are accessible whenever and wherever you need them.' },
    { title: 'Customizable Features:', subtitle: 'Tailor your course settings, pricing, and access levels to suit your audience and goals.' },
    { title: 'Community Building:', subtitle: 'Connect with a vibrant community of learners and educators. Build networks, share ideas, and grow together.' },
];
const title = 'Advantages and Functionality of Groups in Ello';
const subtitle = `
  With Ello, creating and managing groups is easier and more convenient. We offer a<br/>
  wide range of features for communication, organization, and collaboration that will<br/>
  help you connect with like-minded people and interact effectively. Here's what you get:
`;
const mainTitle = `
  Creating or enrolling in online<br/>
  courses is fast and easy
`;
const mainSubtitle = `
  If you're an expert in your field or a creative enthusiast, you can create professional, engaging<br/>
  courses using the robust and user-friendly interface in just a few clicks.
`;
export const OnlineCourses = () => {
    const params = useParams();
    const parallaxController = useParallaxController();
    useEffect(() => {
        window.scrollTo(0, 0);
        const timeout = setTimeout(() => {
            if (parallaxController) {
                parallaxController.update();
            }
        }, 100);
        return () => clearTimeout(timeout);
    }, [params, parallaxController]);
    return (_jsx(S.OnlineCoursesWrap, { children: _jsxs("div", { style: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                background: '#f6f8fd',
                backdropFilter: 'blur(2px)',
                overflow: 'hidden',
            }, children: [_jsx(Title, { title: mainTitle, text: mainSubtitle }), _jsxs(S.OnlineCourses, { children: [_jsx("div", { style: {
                                display: 'flex',
                                justifyContent: 'center',
                                position: 'relative',
                                width: '100%',
                                maxWidth: '1660px',
                                margin: '0 auto',
                            }, children: _jsx(S.OnlineCoursFirstBox, { children: _jsx(S.OnlineCoursSecondBox, { children: _jsx(S.OnlineCoursThirdBox, { children: _jsxs(S.ImgBox, { children: [_jsx("div", { children: _jsx("img", { src: img1, alt: "" }) }), _jsx("div", { children: _jsx("img", { src: img2, alt: "" }) }), _jsx("div", { style: {
                                                        borderRadius: '29px', padding: '6px 7px', overflow: 'hidden', margin: '8px', background: `url(${bg})`, backgroundSize: 'cover',
                                                    }, children: _jsx("img", { style: { borderRadius: '46px' }, src: img3, alt: "" }) }), _jsx("div", { children: _jsx("img", { src: img4, alt: "" }) }), _jsx("div", { children: _jsx("img", { src: img5, alt: "" }) })] }) }) }) }) }), _jsx(S.BG, { children: _jsx(S.OnlineCoursesTtile, { children: _jsxs("p", { children: ["Ello makes creating and enrolling in", ' ', _jsx("strong", { children: "online courses" }), ' ', "fast, easy, and accessible for everyone. Whether you\u2019re looking to share your knowledge or gain new skills, our platform is designed to meet your needs with intuitive features and a seamless experience."] }) }) })] }), _jsx(S.Box, { style: { background: '#fff', width: '100%', padding: '290px 10px 120px' }, children: _jsx(SecondBlock, { greenText: greenTextStudents, titleList: titleListStudents, subTitleList: subTitleListStudents, images: imageList, reverse: { flexDirection: 'row-reverse' }, displayFlex: {
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        } }) }), _jsx("div", { style: { background: '#fff', width: '100%', padding: '290px 10px 120px' }, children: _jsx(SecondBlock, { greenText: greenTextStudentsTwo, titleList: titleListStudentsTwo, subTitleList: subTitleListStudentsTwo, images: imageListTwo, displayFlex: {
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        } }) }), _jsxs("div", { style: { padding: '0 10px', width: '100%', background: '#fff' }, children: [_jsx(Functionality, { title: title, subtitle: subtitle }), _jsx(KeyFeatures, { list: list, grid: {
                                display: 'grid',
                                'grid-template-columns': '1fr 1fr 1fr',
                                'grid-row-gap': '80px',
                            } }), _jsx(StyleGroup.FunctionalityWrap, { style: { marginTop: '120px' }, children: _jsxs(StyleGroup.FunctionalitySubTitle, { children: ["Take advantage of Ello\u2019s powerful tools and start creating or exploring online courses", _jsx("br", {}), "today. Empower yourself and others with knowledge and opportunities in just a few", _jsx("br", {}), "steps!"] }) })] }), _jsx("div", { style: { padding: '0 10px', width: '100%', background: '#fff' }, children: _jsx(WhyChooseElloChannelsBlock, { title: "Start or join your educational journey on Ello", mainImg: img10, paragraphOne: `
            a platform where innovative tools and vibrant communities come
            together to make learning and teaching seamless, engaging, and
            accessible from anywhere.
          ` }) })] }) }));
};
