import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormSuport from './FormSuport';
import BG from '../../assets/images/BG.svg';
import IconSvg from '../../ui/IconSvg';
import { S } from './SupportStyle';
const Support = () => {
    const navigate = useNavigate();
    const [bgImage, setBgImage] = useState('');
    const onGoBack = () => {
        navigate('/');
    };
    useEffect(() => {
        setBgImage(BG);
    }, []);
    return (_jsxs(S.SupportRightContent, { style: {
            width: '100%',
            backgroundImage: `url(${bgImage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            padding: '0 10px 40px',
        }, children: [_jsx(S.SupportHeader, { children: _jsx("div", { "aria-hidden": true, className: "pointer", onClick: onGoBack, children: _jsx(IconSvg, { name: "arrow-back", stroke: "#000000" }) }) }), _jsx("div", { className: "support__form", children: _jsx(FormSuport, {}) })] }));
};
export default Support;
