import styled from 'styled-components';
export const S = {
    ImagesWrap: styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10vw 0 7vw;
    margin: 0 auto;
    max-width: 2000px; /* Максимальна ширина контейнера */
    overflow: hidden;

    img {
      flex: 1 1 auto;
      max-width: 100%; /* Максимальна ширина зображень */
      height: auto; /* Збереження пропорцій зображення */
      object-fit: contain; /* Зображення не обрізаються */
    }

    img:nth-child(1) {
      z-index: 1;
      max-height: 47vh;

      @media (max-width: 1207px) {
        max-height: 34vh;
      }

      @media (max-width: 993px) {
        max-height: 21vh;
      }

      @media (max-width: 612px) {
        max-height: 12vh;
      }
    }

    img:nth-child(2) {
      z-index: 2;
      margin-left: -10vw;
      max-height: 64vh;

      @media (max-width: 1207px) {
        max-height: 51vh;
      }

      @media (max-width: 993px) {
        max-height: 30vh;
      }

      @media (max-width: 612px) {
        max-height: 20vh;
      }
    }

    img:nth-child(3) {
      z-index: 3;
      margin-left: -5vw;
      max-height: 54vh;

      @media (max-width: 1207px) {
        max-height: 41vh;
      }

      @media (max-width: 993px) {
        max-height: 24vh;
      }

      @media (max-width: 612px) {
        max-height: 15vh;
      }
    }

    img:nth-child(4) {
      z-index: 1;
      margin-left: -7vw;
      max-height: 47vh;

      @media (max-width: 1207px) {
        max-height: 34vh;
      }

      @media (max-width: 993px) {
        max-height: 21vh;
      }

      @media (max-width: 612px) {
        max-height: 12vh;
      }
    }

    /* Медіа-запити для адаптивності */

    /* Мобільні телефони до 576px */
    @media (max-width: 576px) {
      padding: 20vw 0 10vw;
      
      img {
        max-height: 30vh;
      }

      img:nth-child(2), img:nth-child(3), img:nth-child(4) {
        margin-left: -5vw;
      }
    }

    /* Планшети до 768px */
    @media (max-width: 768px) {
      padding: 15vw 0 8vw;
      
      img {
        max-height: 35vh;
      }

      img:nth-child(2), img:nth-child(3), img:nth-child(4) {
        margin-left: -7vw;
      }
    }

    /* Планшети до 992px */
    @media (max-width: 992px) {
      padding: 29vw 0 7vw;
      
      img {
        max-height: 38vh;
      }

      img:nth-child(2), img:nth-child(3), img:nth-child(4) {
        margin-left: -8vw;
      }
    }

    /* Великі екрани (від 1200px і більше) */
    @media (min-width: 1200px) {
      padding: 10vw 0 7vw;
      
      img {
        max-height: 50vh;
      }

      img:nth-child(2), img:nth-child(3), img:nth-child(4) {
        margin-left: -10vw;
      }

      img:nth-child(3) {
        margin-left: -7vw;
      }
    }
  `,
};
