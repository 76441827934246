import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable camelcase */
import classNames from 'classnames';
import { memo } from 'react';
const TextArea = ({ field, form: { touched, errors }, className, label, inputMode, maxLength, maxLengthIndicator, tabIndex, is_required, onTextArea, ...props }) => (_jsxs("div", { className: classNames('input', {
        error: touched[field.name] && errors[field.name],
    }), children: [_jsxs("div", { className: "input-wrapper", children: [_jsx("textarea", { className: classNames('form-control', className, {
                        'as-disabled': !field.value,
                    }), dir: "auto", ...field, ...props, tabIndex: tabIndex, maxLength: maxLength, inputMode: inputMode, onChange: (event) => onTextArea(event) }), label && !field.value && (_jsxs("label", { children: [is_required && _jsx("span", { children: "* " }), label] })), maxLengthIndicator && (_jsx("div", { className: "max-length-indicator", children: maxLengthIndicator }))] }), errors[field.name] && (_jsx("p", { className: "input-notification--error", children: String(errors[field.name]) }))] }));
export default memo(TextArea);
