import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import apple from '../../../assets/images/accout-delete/apple.png';
import android from '../../../assets/images/accout-delete/android.png';
import { S } from '../../../pages/DeleteAccounts/DeleteAccountsStyle';
const Button = ({ Wrapper, onClick, name, isActive, imgSrc, text, }) => (_jsxs(Wrapper, { onClick: onClick, type: "button", name: name, className: isActive ? 'active' : '', children: [_jsx("img", { src: imgSrc, alt: "" }), _jsx("span", { children: text })] }));
export const Buttons = ({ titleBtn, onFunc, numberBtn }) => (_jsx(S.ButtonsWrap, { children: [
        {
            Wrapper: S.ButtonLeft,
            imgSrc: apple,
            text: 'Apple (iOS)',
            name: numberBtn[0],
        },
        {
            Wrapper: S.ButtonRight,
            imgSrc: android,
            text: 'Google (Android)',
            name: numberBtn[1],
        },
    ].map(({ Wrapper, imgSrc, text, name, }) => (_jsx(Button, { Wrapper: Wrapper, onClick: (event) => {
            const target = event.currentTarget;
            onFunc(target.name);
        }, name: name, isActive: titleBtn === name, imgSrc: imgSrc, text: text }, name))) }));
