import styled, { keyframes } from 'styled-components';
const appear = keyframes `
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
export const LetterStyle = styled.p `
  display: inline-block;
  opacity: 0;
  animation: ${appear} 0.5s forwards;
  animation-delay: ${({ delay }) => delay}s;
  white-space: pre;
  word-break: keep-all;
`;
