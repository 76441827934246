import styled from 'styled-components';
export const S = {
    OnlineCoursesWrap: styled.div `
    display: flex;
    justify-content: center;
  `,
    OnlineCourses: styled.div `
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
  `,
    OnlineCoursFirstBox: styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 750px;
    height: 750px;
    flex-shrink: 0;
    border-radius: 750px;
    border: 2px solid rgba(12, 90, 204, 10%);
    margin: 100px 0 0;

    @media (max-width: 1024px) {
      width: 550px;
      height: 550px;
      border-radius: 550px;
    };

    @media (max-width: 880px) {
      margin: 40px 0 0;
    };

    @media (max-width: 768px) {
      width: 450px;
      height: 450px;
      border-radius: 450px;
    };
  `,
    OnlineCoursSecondBox: styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 550px;
    height: 550px;
    flex-shrink: 0;
    border-radius: 550px;
    border: 2px solid rgba(12, 90, 204, 50%);

    @media (max-width: 1024px) {
      width: 350px;
      height: 350px;
      border-radius: 350px;
    };

    @media (max-width: 768px) {
      width: 350px;
      height: 350px;
      border-radius: 350px;
    };
  `,
    OnlineCoursThirdBox: styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 350px;
    height: 350px;
    flex-shrink: 0;
    border-radius: 350px;
    border: 2px solid #0C5ACC;

    @media (max-width: 1024px) {
      width: 150px;
      height: 150px;
      border-radius: 150px;
    };

    @media (max-width: 768px) {
      width: 50px;
      height: 50px;
      border-radius: 50px;
    };
  `,
    BG: styled.div `
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
  `,
    OnlineCoursesTtile: styled.div `
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    background: rgba(255, 255, 255, 0.97);
    padding: 100px 0 100px;

    p {
      color: var(--Color-Text-Dark, #070708);
      font-family: Poppins;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      max-width: 1046px;
      text-align: center;
      margin: 0 auto;

      strong {
        color: var(--Color-Text-Dark, #070708);
        font-weight: 600;
      }

      @media (max-width: 1024px) {
        font-size: 20px;
        line-height: 110%;
      };
    }

    
    @media (max-width: 1024px) {
      padding: 70px 10px 0;
    };

    @media (max-width: 477px) {
      padding: 20px 10px 0;
    };
  `,
    ImgBox: styled.div `
    position: absolute;
    top: 0;
    bottom: 205px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 10px;

    div {
      img {
        object-fit: contain;
        width: 100%;
        padding: 5px;
        @media (max-width: 1200px) {
          border-radius: 31px !important;
        };
      }

      &:first-child {
        @media (max-width: 1024px) {
          display: none;
        };
      }

      &:nth-child(3) {
        @media (max-width: 1024px) {
          max-height: 467px;

          img {
            border-radius: 44px !important;
          }
        };

        @media (max-width: 768px) {
          max-height: 378px;

          img {
            border-radius: 35px !important;
          }
        };

        @media (max-width: 577px) {
          img {
            border-radius: 30px !important;
            padding: 1px 2px !important; 
          }
        };

        @media (max-width: 477px) {
          img {
            border-radius: 24px !important;
            padding: 1px 2px !important; 
          }
        };
      }

      &:last-child {
        @media (max-width: 1024px) {
          display: none;
        };
      }
    }

    @media (max-width: 1024px) {
      bottom: 85px;
    };
  `,
    Box: styled.div `
    @media (max-width: 768px) {
      padding: 213px 10px 0 !important;
    };
  `,
};
