import styled from 'styled-components';
const AccountArrow = styled.button `
  margin: 16px 27px;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
const AccountImg = styled.img `
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media screen and (max-width: 882px) {
    display: block;
    margin: 0 auto;
    width: 80%;
    height: 80%;
    padding-bottom: 20px;
  }
`;
const AccountBoxFirst = styled.div `
  display: grid;
  grid-template-columns: 1fr 0.6fr;
  gap: 10px;
  justify-content: space-between;
  padding: 140px 15px;
  max-height: 650px;
  max-width: 1408px;
  margin: 0 auto;

  @media screen and (max-width: 882px) {
    display: flex;
    flex-direction: column-reverse;
    max-height: 100%;
    padding: 60px 15px;
  }
`;
const AccountBoxLast = styled.div `
  max-width: 1408px;
  margin: 0 auto;
  padding: 130px 15px 120px;

  @media screen and (max-width: 768px) {
    padding: 70px 15px 60px;
  }
`;
const AccountTitleH1 = styled.h1 `
  color: #070708;
  font-family: 'Public Sans', sans-serif;
  font-size: 52px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 24px;

  span {
    color: #0a49a5;
    font-family: 'Public Sans', sans-serif;
    font-size: 52px;
    font-weight: 700;
    line-height: normal;

    @media screen and (max-width: 768px) {
      font-size: 32px;
    }
  }

  @media screen and (max-width: 882px) {
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
`;
const AccountParagraph = styled.p `
  color: #070708;
  font-family: 'Public Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  padding-bottom: 32px;

  span {
    color: #e14459;
    font-family: 'Public Sans', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 140%;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;
const AccountMain = styled.div `
  background-color: #f5f9fe;
`;
const AccountTitleH2 = styled.h2 `
  color: #070708;
  text-align: center;
  font-family: 'Public Sans', sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: normal;
  padding: 120px 15px 0;

  @media screen and (max-width: 882px) {
    padding: 60px 15px 0;
  }
`;
const AccountWrapper = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
  padding: 80px 15px;
  border-bottom: 1px solid #d9d9d9;

  &:last-child {
    border-bottom: none;
  }

  @media screen and (max-width: 882px) {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    padding: 40px 15px;
  }
`;
const AccountWrapperGrid = styled.div `
  grid-template-columns: 0.5fr 1fr;
`;
const StepsTitleBlock = styled.div `
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  align-self: flex-start;

  @media screen and (max-width: 882px) {
    margin: 0 0 32px;
  }
`;
const StepsNumber = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 22px;
  border-radius: 50%;
  border: 1px solid #0a49a5;
  margin-right: 24px;
  color: #0a49a5;
  text-align: center;
  font-family: 'Public Sans', sans-serif;
  font-size: 34px;
  font-weight: 600;
  line-height: normal;
`;
const StepsTitle = styled.h3 `
  color: #070708;
  font-family: 'Public Sans', sans-serif;
  font-size: 34px;
  font-weight: 700;
  line-height: normal;
`;
const StepsList = styled.ul `
  color: #070708;
  font-family: 'Public Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 180%;
  list-style-type: disc;
  padding-left: 25px;

  span {
    color: #0a49a5;
    font-family: 'Public Sans', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 180%;
  }
`;
const ButtonsWrap = styled.div `
  display: flex;
  justify-content: center;

  @media screen and (max-width: 882px) {
    padding: 20px 0;
  }
`;
const ButtonLeft = styled.button `
  display: flex;
  width: 220px;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 11px;
  border-radius: 30px 0 0 30px;
  border: 1px solid #d8e7fd;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    background: #d8e7fd;
  }
`;
const ButtonRight = styled(ButtonLeft) `
  border-radius: 0 30px 30px 0;
  gap: 12px;
`;
const ImportantNoteWrap = styled.div ``;
const TitleInner = styled.div `
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;
const Img = styled.img `
  margin-right: 24px;
`;
const Title = styled.h3 `
  color: #070708;
  font-family: 'Public Sans', sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
const Button = styled.button `
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #0a49a5;
  font-family: 'Public Sans', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  text-decoration-line: underline;
  margin-left: 10px;
`;
const Paragraph = styled.p `
  color: #070708;
  font-family: 'Public Sans', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 32.4px */
  margin-bottom: 32px;
`;
export const S = {
    AccountArrow,
    AccountImg,
    AccountBoxFirst,
    AccountBoxLast,
    AccountTitleH1,
    AccountParagraph,
    AccountMain,
    AccountTitleH2,
    AccountWrapper,
    AccountWrapperGrid,
    StepsTitleBlock,
    StepsNumber,
    StepsTitle,
    StepsList,
    ButtonsWrap,
    ButtonLeft,
    ButtonRight,
    ImportantNoteWrap,
    TitleInner,
    Img,
    Title,
    Button,
    Paragraph,
};
