import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import { useRef, useState, } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import Input from '../../ui/Formik/Input';
import TextArea from '../../ui/Formik/TextArea/TextArea';
import { CaptchaWrapper } from '../../ui/CaptchaWrapper/CaptchaWrapper';
import { Select } from '../../ui/Select/Select';
import { DropFile } from '../../ui/DropFile/DropFile';
import DropSvg from '../../assets/svg-icon/drop.svg';
import { uploadFile } from '../../services/supportService';
import './FormSuport.scss';
const initialValueStepTwo = {
    captcha_code: '',
    text: '',
    username: '',
    category_id: null,
    file: '',
};
const validationSchemaStepTwo = Yup.object().shape({
    username: Yup.string()
        // .required('Username is required')
        .min(3, 'Min length')
        .max(20, 'Max length'),
    text: Yup.string()
        .nullable()
        .test('len', 'Must be exactly 5 characters', (val) => Number(val?.length) <= 255)
        .required('Description is required')
        .min(3, 'validation_length_title'),
    captcha_code: Yup.string().required('Captcha is required'),
    category_id: Yup.number().required('Category is required'),
    file: Yup.string(),
});
export const StepTwo = ({ onSubmitForm, categories, onSession }) => {
    const captchaRef = useRef(null);
    const [file, setFile] = useState(null);
    return (_jsx(Formik, { initialValues: initialValueStepTwo, validationSchema: validationSchemaStepTwo, onSubmit: (values, { resetForm }) => {
            onSubmitForm(values);
            // setSubmitting(false);
            resetForm();
        }, children: ({ errors, values, setFieldValue }) => {
            const onActiveCaptcha = (value) => {
                setFieldValue('captcha_code', value);
            };
            const onCategory = (value) => {
                setFieldValue('category_id', value);
            };
            const onTextArea = (event) => {
                const { value } = event.target;
                value.length <= 255 && setFieldValue('text', value);
            };
            const handleChange = (data) => {
                const formData = new FormData();
                formData.append('file', data);
                formData.append('h-captcha-response', values.captcha_code);
                (async () => {
                    try {
                        // Завантаження країн
                        const response = await uploadFile(formData);
                        onSession(response.data.session); // Зберігаємо країни в стейті
                    }
                    catch (error) {
                        console.error('Failed to fetch countries:', error);
                    }
                })();
                setFieldValue('file', data);
                setFile(data);
            };
            return (_jsx(Form, { className: "", children: _jsx("div", { className: "form-container", children: _jsxs("div", { className: "form-inner form-inner-width", children: [_jsxs("div", { className: "support__header-form", children: [_jsx("div", { className: "support__header-form-title", children: "Connect with Support" }), _jsx("div", { className: "input-label-name margin-none", children: "Step 2 of 2" })] }), _jsx(Field, { name: "username", component: Input, 
                                // label={t('Username')}
                                labelName: "Ello Messenger Username created during registration.", value: values.username }), _jsx(Field, { type: "text", name: "category_id", component: Select, onchange: onCategory, list: categories, 
                                // label={t('Support topic')}
                                labelName: "Support topic" }), _jsxs("div", { className: "support__description", children: [_jsx(Field, { name: "text", component: TextArea, placeholder: "Request Description", className: "textarea", value: values.text, onTextArea: onTextArea }), _jsx("span", { className: "support__count", children: 255 - (values?.text?.length || 0) })] }), _jsx("div", { className: "support__text-desc", children: "Describe your request in as much detail as possible so that we can help you." }), _jsx("div", { className: !values.captcha_code ? 'pointer-events' : '', children: _jsx(Field, { name: "file", component: DropFile, file: file, svg: DropSvg, handleChange: handleChange }) }), _jsx("div", { className: `error-custom ${values.captcha_code ? 'opacity' : ''}`, children: "First confirm the captcha" }), _jsx("div", { className: "support__captcha", children: _jsx(CaptchaWrapper, { onActiveCaptcha: onActiveCaptcha, errorCaptcha: errors.captcha_code, captchaRef: captchaRef }) }), _jsx("div", { className: "form-submit", children: _jsx("button", { className: classNames('support__btn', {
                                        disabled: Object.entries(errors).length || !values.captcha_code,
                                    }), type: "submit", children: "Send" }) })] }) }) }));
        } }));
};
