import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useParallaxController } from 'react-scroll-parallax';
import { S } from './StayUpToDayStyles';
import { Title } from '../OnlineCourses/Title';
import { S as OnlineCourses } from '../OnlineCourses/OnlineCoursesStyles';
import bg from '../../assets/images/free&chanel/2.png';
import img1 from '../../assets/images/Stay-up-to-date/2.png';
import img4 from '../../assets/images/Stay-up-to-date/4.png';
import img5 from '../../assets/images/Stay-up-to-date/5.png';
import img6 from '../../assets/images/Stay-up-to-date/6.png';
import img7 from '../../assets/images/Stay-up-to-date/7.png';
import { SecondBlock } from '../../components/FreePaidComponent/SecondBlock/SecondBlock';
import { WhyChooseElloChannelsBlock } from '../../components/FreePaidComponent/WhyChooseElloChannelsBlock/WhyChooseElloChannelsBlock';
const mainTitle = `
  Stay up-to-date with your subscriptions
`;
const mainSubtitle = `
  The feed is the primary system through which you are exposed to a posted content. The feed
  highlights information that includes channel posts, news, and media, among other updates.
  You can customize the feed at any time.
`;
const greenTextDynamic = 'Feed';
const titleListDynamic = 'Dynamic updates<br/> and alerts';
const subTitleListDynamic = `
  Stay up to date with all the changes! In Feed, you
  receive instant notifications about new posts,
  comments, and important events. All updates
  appear in real time, allowing you to quickly react
  to new publications, reply to messages, and
  share a lot of interesting content with your
  friends.
`;
const imageList = [img4];
const greenTextCreating = 'From Creating Content';
const titleListCreating = 'Ello empowers educators with intuitive tools to design and deliver impactful courses effortlessly.';
const subTitleListCreating = `
  Build engaging lessons, incorporate multimedia elements,
  and structure your content with ease, all from a user-friendly
  platform. Whether you’re teaching a small group or reaching
  a global audience, Ello equips you with everything needed to
  create a compelling and professional learning experience.
  Focus on sharing your expertise while Ello takes care of the
  rest.
`;
const imageListCreating = [img5, img6];
const StayUpToDay = () => {
    const params = useParams();
    const parallaxController = useParallaxController();
    useEffect(() => {
        window.scrollTo(0, 0);
        const timeout = setTimeout(() => {
            if (parallaxController) {
                parallaxController.update();
            }
        }, 100);
        return () => clearTimeout(timeout);
    }, [params, parallaxController]);
    return (_jsx(S.StayUpToDay, { children: _jsxs("div", { style: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                background: '#f6f8fd',
                backdropFilter: 'blur(2px)',
            }, children: [_jsx(Title, { title: mainTitle, text: mainSubtitle }), _jsxs(S.StayUpToDayInner, { children: [_jsx(S.StayUpToDayFirstBox, { children: _jsx(S.StayUpToDaySecondBox, { children: _jsx(S.StayUpToDayThirdBox, { children: _jsx(S.StayUpToDayImgBox, { children: _jsx("div", { style: {
                                                borderRadius: '10px', overflow: 'hidden', padding: '12px', background: `url(${bg})`,
                                            }, children: _jsx("img", { style: { borderRadius: '41px' }, src: img1, alt: "" }) }) }) }) }) }), _jsx(OnlineCourses.BG, { children: _jsx(OnlineCourses.OnlineCoursesTtile, { children: _jsxs("p", { children: ["The", ' ', _jsx("strong", { children: "Feed" }), ' ', "is your main source for staying connected and informed. It's the heart of your experience in Ello, where you can discover the latest channel posts, news, media, and more. This dynamic system ensures you're always in the loop with real-time updates tailored to your interests."] }) }) })] }), _jsx("div", { style: { background: '#fff', width: '100%', padding: '190px 10px 120px' }, children: _jsx(SecondBlock, { greenText: greenTextDynamic, titleList: titleListDynamic, subTitleList: subTitleListDynamic, images: imageList, displayFlex: {
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        } }) }), _jsx("div", { style: { background: '#fff', width: '100%', padding: '190px 10px 120px' }, children: _jsx(SecondBlock, { greenText: greenTextCreating, titleList: titleListCreating, subTitleList: subTitleListCreating, images: imageListCreating, reverse: { flexDirection: 'row-reverse' }, displayFlex: {
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        } }) }), _jsx("div", { style: { width: '100%', background: '#fff' }, children: _jsx(WhyChooseElloChannelsBlock, { title: "Start or join your educational journey on Ello", mainImg: img7, paragraphOne: `
            a platform where innovative tools and vibrant communities come
            together to make learning and teaching seamless, engaging, and
            accessible from anywhere.
          ` }) })] }) }));
};
export default StayUpToDay;
