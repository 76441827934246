export const navList = [
    'Personal',
    'Business',
    'AI Text',
    'AI Image',
    'Channels',
    'Groups',
    'Online Courses',
    'Feed',
    'Analytics',
    'Financials',
];
export const pageHotHeader = [
    '/support',
    '/terms',
    '/ai-terms',
    '/delete-account',
    '/privacy-policy',
    '/community-guidelines',
];
