import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { S } from './ElloPayStyles';
import { S as Style } from '../PersonalAccounts/PersonalAccountsStyles';
import { TextContainer } from '../TextContainer/TextContainer';
import { ButtonsContainer } from '../ButtonsContainer/ButtonsContainer';
import phone from '../../assets/images/ello-pay/1.png';
import modern from '../../assets/images/ello-pay/modern-art.png';
import payPal from '../../assets/images/ello-pay/pay-pal.png';
import card from '../../assets/images/ello-pay/2.png';
import audio from '../../assets/images/ello-pay/3.png';
import dolar from '../../assets/images/ello-pay/4.png';
import { navList } from '../../CONST/nav-list';
import { ERoutesLink } from '../../CONST/routes-link';
const title = 'Ello Pay';
const text = 'Manage your finances effortlessly with Ello Pay. Track every transaction, from deposits to withdrawals, while keeping your financial goals in focus. Multiple payment options with bank-grade security ensure your money moves safely and privately.';
const ElloPay = () => {
    const [bgScale, setBgScale] = useState(0);
    const wrapperRef = useRef(null);
    useEffect(() => {
        const handleScroll = () => {
            if (wrapperRef.current) {
                const { top, height } = wrapperRef.current.getBoundingClientRect();
                const windowHeight = window.innerHeight;
                // Розрахунок прогресу скролу
                const progress = Math.min(1, Math.max(0, (windowHeight - top) / (windowHeight + height)));
                setBgScale(progress); // Від 0 до 1
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Ініціалізація
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (_jsx(S.ElloPay, { id: navList[8].toLowerCase(), children: _jsxs(S.ElloPayWrapper, { ref: wrapperRef, style: {
                '--dynamic-bg-scale': bgScale,
            }, children: [_jsx(S.ElloPayContentText, { children: _jsxs(Style.TextWrap, { children: [_jsx(TextContainer, { title: title, paragraph: text }), _jsx(ButtonsContainer
                            // btnLeftName="Remove"
                            , { 
                                // btnLeftName="Remove"
                                btnRightName: "Learn More", link: ERoutesLink.ELLO_PAY, children: _jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "18", height: "19", viewBox: "0 0 18 19", fill: "none", children: [_jsx("path", { d: "M10.8225 4.94727L15.375 9.49977L10.8225 14.0523", stroke: "#0A49A5", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M2.625 9.5H15.2475", stroke: "#0A49A5", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" })] }) })] }) }), _jsxs(S.ElloPayContentImages, { children: [_jsx(S.LargeImage, { children: _jsx("img", { src: phone, alt: "phone" }) }), _jsx(S.ModernArt, { src: modern, alt: "Modern" }), _jsx(S.PayPal, { src: payPal, alt: "PayPal" }), _jsx(S.Card, { src: card, alt: "PayPal" }), _jsx(S.Dolar, { src: dolar, alt: "Dolar" }), _jsx(S.Audio, { src: audio, alt: "Audio" })] })] }) }));
};
export default ElloPay;
