import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { LoadingSmall } from '../../ui/LoadingSmall/LoadingSmall';
import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';
import { StepThree } from './StepThree';
import { Finish } from './Finish';
import { KEY_WEB_3_FORM } from '../../CONST/key-web3Form';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isEmptyObject = (obj) => Object.entries(obj).length === 0;
export const BotForm = ({ step, onStep, setActive, resetAll, setCreateAnother, createAnother, isOpenForms, }) => {
    const [successfully, setSuccessfully] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fileLogo, setFileLogo] = useState(null);
    const [fileBackground, setFileBackground] = useState(null);
    const [prompts, setPrompts] = useState([]);
    const reset = () => {
        resetAll();
        setSuccessfully(false);
        setCreateAnother(false);
    };
    const onGetFile = (file, logo) => {
        logo ? setFileLogo(file) : setFileBackground(file);
    };
    // Валідаційна схема
    const validationSchema = Yup.object({
        name: Yup.string()
            .max(128, 'Maximum 50 characters allowed')
            .required('Name is required'),
        email: Yup.string()
            .email('Invalid email format')
            .max(128, 'Maximum 50 characters allowed')
            .required('Email is required'),
        ai_model: Yup.string()
            .required('AI Model is required'),
        category: Yup.string()
            .required('Category is required'),
        character: Yup.string()
            .required('Character is required'),
        basicDescription: Yup.string()
            .max(128, 'Maximum 128 characters allowed')
            .required('Basic description is required'),
        detailedDescription: Yup.string()
            .max(210, 'Maximum 210 characters allowed')
            .required('Detailed description is required'),
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const activeForm = (values, setFieldValue) => {
        switch (step) {
            case 2:
                return (_jsx(StepTwo, { setPrompts: setPrompts, prompts: prompts }));
            case 3:
                return successfully ? _jsx(Finish, {}) : _jsx(StepThree, { onGetFile: onGetFile });
            default:
                return _jsx(StepOne, { setFieldValue: setFieldValue });
        }
    };
    const handleNextClick = (e) => {
        e.preventDefault();
        onStep(1, true);
        step + 1 === 2 && setActive(true);
    };
    const handleSubmit = async (values, resetForm) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('Type bot', isOpenForms);
        formData.append('Name bot', values.name);
        formData.append('Email', values.email);
        formData.append('Category', values.category);
        formData.append('Character to your AI bot', values.character);
        formData.append('AI Model', values.ai_model);
        formData.append('Basic description', values.basicDescription);
        formData.append('Detailed description', values.detailedDescription);
        prompts.forEach((value, index) => {
            formData.append(`Prompt name ${index + 1}`, value.label || 'Empty');
            formData.append(`Prompt description ${index + 1}`, value.prompt || 'Empty');
        });
        formData.append('Logo', fileLogo || new Blob());
        formData.append('Background', fileBackground || new Blob());
        formData.append('access_key', KEY_WEB_3_FORM);
        try {
            const response = await fetch('https://api.web3forms.com/submit', {
                method: 'POST',
                body: formData,
            });
            if (response.ok) {
                setSuccessfully(true);
                setLoading(false);
                resetForm();
                setCreateAnother(true);
                setPrompts([]);
            }
            else {
                setLoading(false);
            }
        }
        catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            setLoading(false);
            // eslint-disable-next-line no-alert
            alert('An error occurred while submitting the form.');
        }
    };
    return (_jsx(Formik, { initialValues: {
            name: '',
            email: '',
            category: '',
            character: '',
            ai_model: '',
            logo: '',
            background: '',
            basicDescription: '',
            detailedDescription: '',
            prompts: [{ label: '', prompt: '' }],
        }, validationSchema: validationSchema, onSubmit: (values, { resetForm }) => {
            handleSubmit(values, resetForm);
        }, children: ({ values, setFieldValue, errors, isValid, dirty, }) => {
            useEffect(() => {
                const shouldDisable = step === 2
                    ? prompts.length === 0
                    : !isValid || !dirty;
                setActive(shouldDisable); // Синхронізація стану `active`
            }, [values, errors, isValid, dirty, prompts]);
            return (_jsxs(Form, { className: "input-form", children: [activeForm(values, setFieldValue), step === 3 ? (createAnother ? (_jsx("button", { type: "button", onClick: reset, className: "btn-submit", children: "Create Another AI Bot" })) : (_jsx("button", { type: "submit", disabled: !isEmptyObject(errors) && loading, className: "btn-submit", children: loading ? _jsx(LoadingSmall, {}) : 'Finish' }))) : (step === 2
                        ? (_jsx("button", { type: "button", onClick: handleNextClick, disabled: !prompts.length, className: "btn-submit", children: "Next" }))
                        : (_jsx("button", { type: "button", onClick: handleNextClick, disabled: step === 2 ? prompts.length === 0 : !isValid || !dirty, className: "btn-submit", children: "Next" })))] }));
        } }));
};
