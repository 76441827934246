import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ERoutesLink } from '../../CONST/routes-link';
import { S } from './FooterStyles';
const navigation = [
    { nav: 'AI Terms', link: ERoutesLink.AI_TERMS },
    { nav: 'Privacy Policy', link: ERoutesLink.PRIVACY_POLICY },
    // { nav: 'Cookie Policy', link: ERoutesLink.COOKIE_POLICY },
    { nav: 'Terms', link: ERoutesLink.TERMS },
    { nav: 'Community guidelines', link: ERoutesLink.COMMUNITY_GUIDELINES },
    { nav: 'Delete Account', link: ERoutesLink.DELETE_ACCOUNT },
    { nav: 'Support', link: ERoutesLink.SUPPORT },
    // { nav: 'FAQ', link: ERoutesLink.FAQ },
    // { nav: 'Manuals', link: ERoutesLink.MANUALS },
];
export const Footer = () => (_jsxs(S.footer, { children: [_jsx(S.footerLeft, { children: "\u00A9 2025 Ello Messenger Corporation" }), _jsx(S.footerRight, { children: _jsx(S.footerListNav, { children: navigation.map((nav) => (_jsx(S.footerItem, { children: _jsx(S.footerLink, { to: nav.link, children: nav.nav }) }, nav.nav))) }) })] }));
