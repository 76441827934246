import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { S } from './LatestPostsStyles';
import { S as Style } from '../PersonalAccounts/PersonalAccountsStyles';
import { ButtonsContainer } from '../ButtonsContainer/ButtonsContainer';
import { TextContainer } from '../TextContainer/TextContainer';
import { CylinderButton } from '../CylinderButton/CylinderButton';
import img1 from '../../assets/images/latest-post/1.png';
import img2 from '../../assets/images/latest-post/2.png';
import panda from '../../assets/images/latest-post/panda.png';
import { navList } from '../../CONST/nav-list';
import { ERoutesLink } from '../../CONST/routes-link';
const title = 'Stay up-to-date with your subscriptions';
const text = 'Your feed is the primary system for content discovery, ensuring you never miss out on what matters most to you. Whether it\'s breaking news, trending topics, or updates from channels you follow, it\'s all at your fingertips. Stay informed, engaged, and connected with your personalized feed.';
const LatestPosts = () => (_jsxs(S.LatestPostsWrapper, { id: navList[7].toLowerCase(), children: [_jsxs(S.LatestPostsTextWrapper, { children: [_jsxs(CylinderButton, { background: "--Color-Red", color: "--Color-Text-White", children: [_jsxs("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M6.50391 10.7474C6.50391 11.7149 7.24641 12.4949 8.16891 12.4949H10.0514C10.8539 12.4949 11.5064 11.8124 11.5064 10.9724C11.5064 10.0574 11.1089 9.73488 10.5164 9.52488L7.49391 8.47488C6.90141 8.26488 6.50391 7.94238 6.50391 7.02738C6.50391 6.18738 7.15641 5.50488 7.95891 5.50488H9.84141C10.7639 5.50488 11.5064 6.28488 11.5064 7.25238", stroke: "white", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M9 4.5V13.5", stroke: "white", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M11.25 16.5H6.75C3 16.5 1.5 15 1.5 11.25V6.75C1.5 3 3 1.5 6.75 1.5H11.25C15 1.5 16.5 3 16.5 6.75V11.25C16.5 15 15 16.5 11.25 16.5Z", stroke: "white", strokeLinecap: "round", strokeLinejoin: "round" })] }), _jsx("span", { children: "Engage with your customers" })] }), _jsxs(Style.TextWrap, { children: [_jsx(TextContainer, { title: title, paragraph: text }), _jsx(ButtonsContainer
                        // btnLeftName="Download"
                        , { 
                            // btnLeftName="Download"
                            btnRightName: "Learn More", justifyContent: "flex-start", link: ERoutesLink.STAY_UP_TO_DAY, children: _jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "18", height: "19", viewBox: "0 0 18 19", fill: "none", children: [_jsx("path", { d: "M10.8225 4.94727L15.375 9.49977L10.8225 14.0523", stroke: "#0A49A5", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M2.625 9.5H15.2475", stroke: "#0A49A5", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" })] }) })] })] }), _jsxs(S.LatestImagesWrapper, { children: [_jsx(S.RotatingElement, { children: _jsx("img", { src: panda, alt: "panda" }) }), _jsx("img", { src: img1, alt: "phone" }), _jsx("img", { src: img2, alt: "phone" })] })] }));
export default LatestPosts;
