import styled from 'styled-components';
export const S = {
    AdvancedWrap: styled.div `
    max-width: 1300px;
    width: 100%;
    padding: 0 10px 160px;
    margin: 0 auto;
    /* @media (max-width: 535px) {
      flex-direction: column;
    }; */
  `,
    TextWrap: styled.div `
    padding: 24px 0 94px;

    @media (max-width: 1024px) {
      padding: 24px 0 94px;
    };

    @media (max-width: 768px) {
      padding: 24px 0 54px;
    };
  `,
};
