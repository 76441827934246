import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useState, useRef } from 'react';
import ArrowDownSvg from '../../assets/svg-icon/arrow-down.svg';
import useOutsideClick from '../../hooks/useOutsideClick';
export const Select = ({ onchange, list = [], field, form: { errors }, labelName, }) => {
    const [text, setText] = useState('');
    const ref = useRef(null);
    const [onOpen, setOnOpen] = useState(false);
    useOutsideClick(ref, () => setOnOpen(false));
    const handleSelect = (category) => {
        // setSelectedCountry(country);
        setOnOpen(false);
        setText(category.title);
        onchange?.(category.id);
    };
    return (_jsx("div", { className: "select-custom", children: _jsxs("div", { ref: ref, className: classNames('select', { active: onOpen }), children: [_jsxs("div", { className: "input input-custom pointer", children: [_jsx("label", { className: "label-custom", htmlFor: "/", children: labelName }), _jsxs("div", { "aria-hidden": true, onClick: () => setOnOpen(true), onFocus: () => setOnOpen(true), className: "input-wrapper", children: [_jsx("input", { readOnly: true, className: "input-item input-item--left-icon form-control as-disabled", type: "text", name: "country", value: text }), _jsx("div", { className: "input-icon input-icon--right", children: _jsx("img", { src: ArrowDownSvg, alt: "" }) })] }), errors[field.name] && (_jsx("p", { className: "input-notification--error bottom-error", children: String(errors[field.name]) }))] }), _jsx("div", { className: "select__drop select__drop-custom select__drop-custom-country", children: _jsx("div", { className: "select__drop-scroll select__drop-scroll-custom", children: _jsx("div", { className: "select__drop-item", children: _jsx("ul", { children: list?.map((category) => (_jsx("li", { className: "countries-list", children: _jsx("button", { onClick: () => handleSelect(category), type: "button", children: category.title }) }, category.id))) }) }) }) })] }) }));
};
