/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-console */
import { isAxiosError } from 'axios';
import { endpoint } from './endpoint';
import requestService from './request-service';
export const getCountries = async () => {
    try {
        const response = await requestService.get(endpoint.support.COUNTRIES);
        return response.data;
    }
    catch (error) {
        if (isAxiosError(error)) {
            console.error(error);
            throw error.response?.data?.errors[0] || 'An error occurred';
        }
        throw error;
    }
};
export const getCategories = async () => {
    try {
        const response = await requestService.post(endpoint.support.CATEGORIES, {});
        return response.data;
    }
    catch (error) {
        if (isAxiosError(error)) {
            console.error(error);
            throw error.response?.data?.errors[0] || 'An error occurred';
        }
        throw error;
    }
};
export const addReport = async (payload) => {
    try {
        const response = await requestService.post(endpoint.support.REPORT_ADD, payload.request);
        payload.reset('The letter was sent successfully');
        return response.data;
    }
    catch (error) {
        payload.reset('The letter was not sent');
        if (isAxiosError(error)) {
            console.error(error);
            throw error.response?.data?.errors[0] || 'An error occurred';
        }
        throw error;
    }
};
export const uploadFile = async (payload) => {
    try {
        const response = await requestService.post(endpoint.support.UPLOAD, payload);
        return response.data.session;
    }
    catch (error) {
        if (isAxiosError(error)) {
            console.error(error);
            throw error.response?.data?.errors[0] || 'An error occurred';
        }
        throw error;
    }
};
